<template>
  <img :src="src" alt="plus-circle" />
</template>

<script>

import src from "../../../../resources/icons/plus-circle.svg";

export default {
  setup() {
    return {
      src
    };
  }
}
</script>
