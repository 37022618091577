<template>
    <transition name="modal-fade">
        <div class="fixed z-10 inset-0 overflow-y-auto">
            <div
                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                >&#8203;</span
                >
                <div
                        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                >
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                            >
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-10 w-10 text-mp-dark-green"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                >
                                    <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                        class="text-mp-dark-green text-lg leading-6 font-semibold text-gray-900"
                                        id="modal-headline"
                                >
                                    {{
                                    this.pet.name +
                                    "'s appointment on " +
                                    this.format(appointment.occurrence)
                                    }}
                                </h3>
                                <div class="mt-2">
                                    <p class="mt-4 text-lg font-semibold text-mp-blue">Notes:</p>
                                    <span class="mt-2">{{ appointment.notes || "None" }}</span>
                                    <p class="mt-4 text-lg font-semibold text-mp-blue">
                                        Medications:
                                    </p>
                                    <span class="mt-2">{{
                                        getMedications(appointment) || "None"
                                        }}</span>
                                    <div class="mt-4 flex">
                                        <p class="w-5/6 text-lg font-semibold text-mp-blue">
                                            Images:
                                        </p>
                                    </div>
                                    <div id="appointment-summary-gallery" class="mt-4">
                                        <div class="grid grid-cols-3 gap-4">
                                            <a v-for="image in images" :key="image.id" :href="image.largeURL"
                                               :data-pswp-width="image.width"
                                               :data-pswp-height="image.height" class="flex justify-center">
                                                <div class="image-container">
                                                    <img class="image" :src="image.thumbnailURL" alt=""/>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <p v-if="images.length <= 0">None</p>
                                    <p class="mt-4 text-lg font-semibold text-mp-blue">Cost:</p>
                                    <span class="mt-2">{{ totalCost }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <base-button
                                primary
                                @click="openAppointment"
                                class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                        >Edit
                        </base-button
                        >
                        <base-button @click="close" class="mr-4">Cancel</base-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
    name: "modal",
    props: {
        appointment: {
            type: Object,
            required: true,
        },
        pet: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            images: [],
            totalCost: "unknown",
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        openAppointment() {
            this.$emit("openAppointmentEvent");
        },
        getMedications(appointment) {
            return appointment.batches
                .map(function (b) {
                    return b.medication.name;
                })
                .join();
        },
        format(date) {
            let d;

            if (date instanceof Date) {
                d = date;
            } else {
                d = new Date(date);
            }

            return d.toLocaleDateString("en-GB", {
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        }
    },
    mounted() {
        this.totalCost = JSON.parse(this.appointment.data).totalCost;

        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#' + 'appointment-summary-gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
            });
            this.lightbox.init();
        }
    },
    beforeMount() {
        this.images = this.appointment.animal_monitoring_images;

        this.images = this.images.map(function (i) {
            return {
                largeURL: i.url,
                thumbnailURL: i.url,
                height: 600,
                width: 600,
            };
        });
    },
    unmounted() {
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
};
</script>

<style scoped>
.image-container {
    width: 100px; /* Set the desired width */
    height: 100px; /* Set the desired height */
    overflow: hidden; /* Hide any overflowing image portions */
}

.image {
    width: 100%; /* Make the image fill the container horizontally */
    height: 100%; /* Make the image fill the container vertically */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
}
</style>