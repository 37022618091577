<template>
  <div>
    <base-horizontal-field label="Old password" class="mt-2">
      <base-input v-model="form.current_password" type="password"
                  :invalid="form.errors.get('current_password')"
                  :is-invalid="!!form.errors.get('current_password')"
      ></base-input>
      <div v-if="form.errors.get('current_password')" class="text-red-500">{{
          form.errors.get('current_password')
        }}
      </div>
    </base-horizontal-field>
    <base-horizontal-field label="New password" class="mt-2">
      <base-input v-model="form.password" type="password"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Confirm new password" class="mt-2">
      <base-input v-model="form.password_confirmation" type="password"
                  :is-invalid="!!form.errors.get('password')"
      ></base-input>
      <div v-if="form.errors.get('password')" class="text-red-500">{{
          form.errors.get('password')
        }}
      </div>
    </base-horizontal-field>
    <base-horizontal-field>
      <p v-if="passwordUpdatedSuccess">Password updated successfully!</p>
    </base-horizontal-field>
    <base-horizontal-field>
      <p v-if="passwordUpdatedUnsuccessful">Password updated!</p>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="$router.go(-1)" class="mr-4">Back</base-button>
        <base-button @click="form.submit()" primary>Update</base-button>
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import BaseHorizontalField from '../shared/BaseHorizontalField';
import User from "../../models/User";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  async beforeMount() {
    await this.getUser();
  },
  components: {BaseHorizontalField},
  data() {
    const initialData = {
      current_password: '',
      password: '',
      password_confirmation: '',
    };

    return {
      passwordUpdatedSuccess: false,
      passwordUpdatedUnsuccessful: false,
      user: Object,
      form: new window.Form(initialData, async form => {
        const user = await User.updatePassword(this.$auth.user().id, form);

        if (!form.message) {
          this.passwordUpdatedSuccess = true;
        }
      })
    }
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async getUser() {
      this.user = this.$auth.user();
    },
  },
  props: {
    id: Number,
  },
}
</script>

<style scoped>
</style>
