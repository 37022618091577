<template>
  <img :src="src" alt="burger-menu" />
</template>

<script>

import src from "../../../../resources/icons/burger-menu.svg";

export default {
  setup() {
    return {
      src
    };
  }
}
</script>
