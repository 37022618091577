<template>
  <button
      class="font-semibold py-1 px-4 rounded"
      style="width: 100%; display: flex; justify-content: center"
      :class="[buttonType]"
      v-bind="$attrs"
      :disabled="isDisabled"
      v-tooltip="!tooltipText ? '' : tooltipText"
  >
    <slot>{{ text }}</slot>
  </button>
</template>

<script>
import 'floating-vue/dist/style.css'

export default {
  props: {
    text: String,
    primary: Boolean,
    danger: Boolean,
    green: Boolean,
    disabled: Boolean,
    tooltipText: String,
  },
  computed: {
    buttonType() {
      if (this.disabled) return "bg-mp-blue text-white opacity-50"
      if (this.primary) return "bg-mp-blue button-bkg text-white text-base";
      if (this.danger) return "bg-red-600 text-white hover:bg-red-700";
      if (this.green) return "bg-mp-green text-white hover:bg-mp-hover-blue";
      return "bg-gray-100 text-gray-700 hover:bg-gray-300";
    },
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>

<style scoped>
.button-bkg {
  text-transform: capitalize;
}

.button-bkg:hover {
  background-color: rgb(21, 21, 172);
}
</style>
