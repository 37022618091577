import Model from "./Model";
import Batch from "./Batch";

export default class Medication extends Model {
    getRelationships() {
        return {
            batches: Batch,
        };
    }

    get icon() {
        return "user";
    }

    get first_batch() {
        if (this.batches.length > 0) {
            return this.batches[0];
        } else {
            return "no batches";
        }
    }

    static async search(query) {
        let data = "";
        if (query) {
            data = await window.Http.get("medications?query=" + query);
        } else {
            data = await window.Http.get("medications");
        }
        return this.make(data);
    }

    static async getAvailableWithPrice() {
        const data = await window.Http.get(
            "medications?include=currentCostPrice,currentRetailPrice,batches"
        );
        return this.make(data);
    }

    static async getById(id) {
        const data = await window.Http.get("medications/" + id);
        return this.make(data);
    }

    static async getByIdWithFlag(id, flag) {
        const data = await window.Http.get("medications/" + id + "?" + flag);
        return this.make(data.data);
    }

    static async create(form) {
        const data = await window.Http.post("medications", form);
        return this.make(data);
    }

    static async createPrice(id, data) {
        const result = await window.Http.post("medications/" + id + "/price", data);
        return this.make(result);
    }
}