<template>
  <div>
    <base-horizontal-field label="Email" class="mt-2">
      <base-input v-model="form.email"></base-input>
    </base-horizontal-field>
    <base-horizontal-field>
      <p v-if="showMessage">Please check your inbox for your password reset link!</p>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button primary @click="form.submit()" class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">Reset
          password
        </base-button>
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import BaseHorizontalField from '../shared/BaseHorizontalField';
import User from "../../models/User";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {BaseHorizontalField},
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      email: '',
    };

    return {
      showMessage: false,
      user: Object,
      form: new window.Form(initialData, async form => {

        const user = await User.sendPasswordResetLink(form);

        this.showMessage = true;
      })
    }
  },
  props: {
    id: Number,
  },
}
</script>

<style scoped>

</style>
