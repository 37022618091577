<template>
  <div>
    <base-panel title="Appointments" class="w-full max-w-4xl mx-auto">
      <template #actions>
        <base-button primary @click="router.push({ name: 'NewAppointment', params: { pet_id: pet.id } })"
                     class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">
          <plus-circle-icon icon="plus-circle" class="fill-current w-4 h-4 mr-2"></plus-circle-icon>
          <span>Add Appointment</span>
        </base-button>
      </template>
      <pet-appointments-table :pet="pet"></pet-appointments-table>
    </base-panel>
  </div>
</template>

<script>
import PetAppointmentsTable from "../../components/admin/PetAppointmentsTable";
import Pet from "../../models/Pet";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {
    PetAppointmentsTable
  },
  props: {
    id: Number
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      loading: false,
      pet: {},
    }
  },
  methods: {
    async getPet() {
      this.loading = true
      try {
        this.pet = await Pet.findWithFlag(this.id, 'appointments')
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false
      }
    },
  },
  beforeMount() {
    this.getPet();
  },
}
</script>

<style scoped>

</style>
