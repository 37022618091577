<template>
  <div>
    <table v-if="$auth.role('superadmin')" class="w-full">
      <tbody>
        <tr
          @click="
            router.push({
              params: { id: $auth.user().id },
              name: 'OnboardVeterinaryClinic',
            })
          "
          class="p-4"
        >
          <td class="p-4">
            <div class="capitalize font-semibold text-lg">
              Onboard veterinary clinic
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  props: {
    pets: Array,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  }
};
</script>

<style scoped>
tr {
  cursor: pointer;
}

td:hover {
  color: #0074b5;
}
</style>
