<template>
  <div class="w-full max-w-4xl mx-auto">
    <div
      v-if="loading"
      class="flex h-16 items-center justify-center font-semibold text-gray-600"
    >
      Loading...
    </div>
    <div v-else>
      <medication-summary-table
        :medication="medication"
      ></medication-summary-table>
    </div>
  </div>
</template>

<script>
import MedicationSummaryTable from "../../components/admin/MedicationSummaryTable";
import Medication from "../../models/Medication";

export default {
  props: {
    id: Number,
  },
  components: {
    MedicationSummaryTable,
  },
  data() {
    return {
      medication: "",
      loading: false,
      pets_loading: false,
    };
  },
  methods: {
    async getMedication() {
      this.loading = true;
      try {
        this.medication = await Medication.getByIdWithFlag(
          this.id,
          "include=batches"
        );
      } catch(error){
          await this.$auth.logout();
          this.router.push({ name: "Login" });
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.getMedication();
  },
};
</script>

<style scoped>
</style>
