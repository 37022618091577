<template>
  <div>
    <div
      v-if="loading"
      class="flex h-16 items-center justify-center font-semibold text-gray-600"
    >
      Loading...
    </div>
    <div v-else class="w-3/6 max-w-4xl mx-auto">
      <div
        class="bg-white border rounded-lg overflow-hidden shadow-lg"
        style="clear: both"
      >
        <div class="flex bg-mp-blue font-semibold py-4 border-b items-center">
          <h1 class="font-semibold px-4 py-2 text-xl flex-1 text-white">
            Thank you!
          </h1>
        </div>
        <div class="p-3">
          You have registered successfully with {{ this.team.name || "" }}!
          <br />
          <img src="../../resources/images/dog-visitor-reg.jpg" alt="dog" />
          <br />
          You don't need to do anything else.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Team from "../models/Team";
export default {
  components: {},
  data() {
    return {
      team: Object,
      loading: false,
    };
  },
  methods: {
    async getTeam() {
      this.loading = true;
      try {
        this.team = await Team.getVisitorRelevantDataById(
          this.$auth.user().team_id
        );
      } catch(error){
          await this.$auth.logout();
          this.router.push({ name: "Login" });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    setTimeout(
      function () {
        this.$auth.logout();
      }.bind(this),
      30000
    );
  },
  beforeMount() {
    this.getTeam();
  },
};
</script>

<style scoped>
</style>
