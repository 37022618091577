<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
          class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-2
          text-center
          sm:block sm:p-0
        "
      >
        <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >&#8203;</span
        >
        <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
        <div
            class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
          <div
              class="
              bg-white
              px-4
              pt-5
              pb-4
              sm:p-6 sm:pb-4
              flex-col
              justify-center
              items-center
            "
          >
            <div class="sm:flex sm:items-start">
              <div
                  class="
                  mx-auto
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-mp-dark-green
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <!-- Heroicon name: at-symbol -->
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                      clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                    class="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                >
                  {{ modalHeadline }}
                </h3>
                <div v-if="!resendToExisting" class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ message }}
                  </p>
                </div>
                <input
                    v-if="!resendToExisting"
                    v-model="input"
                    :class="getInputClass"
                />
                <div>
                  <label class="inline-flex items-center mt-2">
                    <input
                        type="checkbox"
                        class="form-checkbox"
                        v-model="resendToExisting"
                    />
                    <span class="ml-2 text-gray-500"
                    >Resend to existing email address</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                @click="confirm"
                class="
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-transparent
                shadow-sm
                px-4
                py-2
                bg-mp-blue
                text-base
                font-medium
                text-white
                hover:bg-mp-dark-green
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-red-500
                sm:ml-3 sm:w-auto sm:text-sm
              "
            >
              Confirm
            </button>
            <button
                type="button"
                @click="close"
                class="
                mt-3
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                px-4
                py-2
                bg-white
                text-base
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "modal",
  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {input: "", error: false, resendToExisting: false};
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      if (!this.input && !this.resendToExisting) {
        this.error = true;
        return;
      }

      this.$emit("confirmationEvent", {
        email: this.input,
        resend_to_existing: this.resendToExisting,
        requestId: this.requestId
      });

      this.reset();
    },
    reset() {
      this.resendToExisting = false;
      this.error = false;
      this.input = "";
    },
  },
  watch: {
    value() {
      this.input = this.value;
    },
  },
  computed: {
    getInputClass() {
      if (this.error) {
        return "h-12 bg-gray-100 border w-full rounded py-2 mt-2 px-4 text-gray-700 focus:border-purple-500 border-red-500";
      }
      return "h-12 bg-gray-100 border w-full rounded py-2 mt-2 px-4 text-gray-700 focus:border-purple-500";
    },
  },
};
</script>
<style>
</style>