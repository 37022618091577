<template>
    <div class=" max-w-4xl mx-auto">
        <warning-alert v-if="showMicrochipLengthWarning " warning="Microchip Invalid"
                       message="Microchips are normally 15 digits long. On rare occasions a microchip number may be 10 digits. If you have a microchip that doesn’t match this criteria give us a call on 028 96 223 001."></warning-alert>
        <base-panel noPadding>
            <template #search>
                <input
                        class="
            ml-3
            bg-white
            focus:outline-none focus:shadow-outline
            rounded-lg
            py-2
            px-4
            block
            w-full
            appearance-none
            leading-normal
            mr-5
          "
                        :class="(computedMicrochipError) ? 'border-2 border-red-500 focus:border-red-500' : 'border-2 border-green-500 focus:border-green-500'"
                        v-model="microchip"
                        type="text"
                        placeholder="Search microchip"
                        @keyup.enter="microchipAuthorisedSearch()"
                />
            </template>
            <template #icon>
                <div
                        @click="microchipAuthorisedSearch()"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 mr-4 text-white cursor-pointer" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                    </svg>
                </div>
            </template>
        </base-panel>
        <microchip-internal-result-card v-if="!searching && this.internalResult"
                                        :data="internalResult"></microchip-internal-result-card>
        <div v-if="searching" class="justify-center items-center text-center content-center flex-row mt-32">
            <div class="w-1/3 inline-block"></div>
            <loading-spinner class="1/3 inline-block" :size="16"></loading-spinner>
            <div class="w-1/3 inline-block"></div>
        </div>
        <div v-if="externalResult && externalResult.length > 0" class="d-flex justify-center">
            <div v-for="db in externalResult" :key="db.id">
                <microchip-external-result-card :db="db" @resetPage="resetPage"
                                                @searchAgain="searchAgain"></microchip-external-result-card>
            </div>
        </div>
        <microchip-not-found-card
                v-else-if="microchip.length > 0 && !searching && searchCompleted && !internalResult && (externalResult && externalResult.length === 0)"
                @resetPage="resetPage" @searchAgain="searchAgain">
        </microchip-not-found-card>
    </div>
</template>

<script>
import Microchip from "../models/Microchip";
import MicrochipNotFoundCard from "@/components/microchips/MicrochipNotFoundCard";
import MicrochipExternalResultCard from "@/components/microchips/MicrochipExternalResultCard";
import MicrochipInternalResultCard from "@/components/microchips/MicrochipInternalResultCard";
import WarningAlert from "@/components/shared/Alerts/WarningAlert";
import BasePanel from "@/components/shared/BasePanel.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";

export default {
    components: {
        LoadingSpinner,
        BasePanel,
        MicrochipInternalResultCard,
        MicrochipNotFoundCard,
        MicrochipExternalResultCard,
        WarningAlert
    },
    data() {
        const initialData = {
            first_name: "",
            last_name: "",
            email: "",
            home_number: "",
            mobile_number: "",
            postcode: "",
            door_number: "",
            address_line_1: "",
            address_line_2: "",
            town: "",
        };

        return {
            externalResult: null,
            form: new window.Form(initialData, async (form) => {
            }),
            internalResult: null,
            loading: true,
            microchip: "",
            searching: false,
            searchCompleted: false,
            showMicrochipLengthWarning: false,
        };
    },
    watch: {
        microchip(newMicrochip, oldMicrochip) {
            this.showMicrochipLengthWarning = false;
        }
    },
    computed: {
        computedMicrochipError() {
            return !(this.microchip.length === 0 || this.microchip.length === 10 || this.microchip.length === 15);
        },
    },
    methods: {
        async resetPage(resetMicrochip = true) {
            if (resetMicrochip) {
                this.microchip = "";
            }
            this.searching = false;
            this.searchCompleted = false;
            this.internalResult = null;
            this.externalResult = null;
        },
        async searchAgain() {
            await this.resetPage(false)
        },
        async microchipAuthorisedSearch() {
            await this.resetPage(false);
            this.searching = true;
            if (this.microchip.length === 10 || this.microchip.length === 15) {
                const result = await Microchip.authorisedSearch(this.microchip);

                this.internalResult = result.internal?.data;
                this.externalResult = result.external?.mc_dbs;
            } else {
                this.showMicrochipLengthWarning = true;
            }

            this.searchCompleted = true;
            this.searching = false;
        },
    },
    async beforeMount() {
        this.loading = false;
    },
};
</script>

<style>
</style>
