<template>
    <div>
        <base-panel title="Reset password" class="w-full max-w-4xl mx-auto">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <password-reset v-else></password-reset>
        </base-panel>
    </div>
</template>

<script>
    import AccountSettings from "../../components/admin/AccountSettings";
    import PasswordReset from "../../components/admin/PasswordReset";

    export default {
        components: {PasswordReset, AccountSettings},
        data() {
            return {
                team: Object,
                loading: false,
            }
        },

    }
</script>

<style scoped>

</style>
