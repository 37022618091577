<template>
    <div>
        <base-panel title="New Customer" class="w-full max-w-4xl mx-auto">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <create-customer></create-customer>
        </base-panel>
    </div>
</template>

<script>
    import CreateCustomer from "../components/admin/CustomerCreate";

    export default {
        components: {
            CreateCustomer
        },
        data() {
            return {
                customer: '',
                pets: Array,
                loading: false
            }
        },
        methods: {
        },
        mounted() {
        },
        beforeMount(){

        },
    }
</script>

<style scoped>

</style>
