<template>
  <base-panel title="Appointments" id="pet-appointments-table" no-padding>
    <template #actions>
      <base-button primary @click="router.push({ name: 'NewAppointment', params: { pet_id: pet.id } })"
                   class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">
        <plus-circle-icon class="fill-current w-4 h-4 mr-2"></plus-circle-icon>
        <span>Add Appointment</span>
      </base-button>
    </template>
    <p
        v-if="pet.appointments.length < 1"
        class="px-6 mb-4 mt-4 empty-table"
    >
      No appointments for this pet.
    </p>
    <table class="w-full" v-else>
      <tbody>
      <div
          tag="tr"
          v-for="appointment in pet.appointments"
          :key="appointment.id"
      >
        <div @click="showAppointmentSummary(appointment)" class="hover:bg-green-100 cursor-pointer">
          <td class="p-4 w-px">
            <calendar-plus-icon :icon="appointment.icon" class="fill-current w-5"></calendar-plus-icon>
          </td>
          <td class="p-4 pl-0 text-lg">
            {{ formatDate(appointment.occurrence) }}
          </td>
        </div>
      </div>
      </tbody>
    </table>
  </base-panel>
</template>

<script>
import Swal from 'sweetalert2'
import Appointment from "../../models/Appointment";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  props: {
    pet: Object,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async showAppointmentSummary(appointment) {

      const app = await Appointment.findWithFlag(appointment.id, 'batches')

      Swal.fire({
        title: "Appointment for " + this.pet.name,
        html:
            '<b>Date: </b>' + this.getDate(app.occurrence) + '<br>' +
            '<b>Time: </b>' + this.getTime(app.occurrence) + '<br>' +
            '<b>Notes: </b>' + this.isPopulated(app.notes) + '<br>' +
            '<b>Medications: </b>' + this.getMedications(app.batches),
        width: 600,
        padding: '3em',
        cancelButtonText:
            'Dismiss',
        confirmButtonText:
            'Edit',
        showCancelButton: true,
        backdrop: `
                        rgba(155, 155, 181, 0.48)
                        left top
                        no-repeat
                      `
      }).then((result) => {
        if (result.value) {
          this.router.push({name: 'AppointmentDetails', params: {id: appointment.id}})
        }
      })
    },
    isFuture(value) {

      var date = new Date(value);
      var now = new Date()
      now.setDate(now.getDate() - 1)

      if (date > now) {
        return true
      }

      return false
    },
    formatDate(value) {

      var date = new Date(value);

      return date.toLocaleString()
    },
    isPopulated(value) {

      if (value === null) {
        return 'none'
      } else {
        return value
      }
    },
    getDate(value) {

      var date = new Date(value);

      return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    },
    getTime(value) {

      var date = new Date(value);

      return date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
    },
    getMedications(value) {

      const parsedBatches = value.map(b => ([
        b.medication.name,
      ]));

      return parsedBatches.join(', ')
    }
  }
}
</script>

<style scoped>
#pet-appointments-table {
  width: 100%;
}
</style>
