import Swal from "sweetalert2";
<template>
  <div>
    <p v-if="pets.length < 1" class="p-4">No pets found.</p>
    <table v-else class="w-full">
      <thead>
      <tr
          class="uppercase text-sm tracking-wider text-gray-500 text-left bg-gray-100"
      >
        <th class="font-medium p-4">Name</th>
        <th class="font-medium p-4">Breed/Colour</th>
        <th class="font-medium p-4">Age/Sex</th>
        <th class="font-medium p-4">Microchip</th>
        <th class="font-medium p-4 w-px">Actions</th>
      </tr>
      </thead>
      <tbody>
      <router-link
          :to="{ name: 'PetEdit', params: { id: pet.id } }"
          tag="tr"
          :key="pet.id"
          v-for="pet in pets"
          class="border-t hover:bg-indigo-100 cursor-pointer"
      >
        <td class="p-4">
          <div class="capitalize font-semibold text-lg">
            {{ pet.name || "Felix" }}
          </div>
          <div>
            {{ pet.pet_type || "dog" }}
          </div>
        </td>
        <td class="p-4">
          <div class="capitalize">
            {{ pet.breed || "dalmation" }}
          </div>
          <div>
            {{ pet.main_colour || "brown" }}
          </div>
        </td>
        <td class="p-4">
          <div>
            {{ pet.age || "1" }}
          </div>
          <div>
            {{ pet.sex || "female" }}
          </div>
        </td>
        <td class="p-4">
          <div>
            {{ pet.microchip || "12345" }}
          </div>
        </td>
        <td class="p-4 w-px">
          <div class="flex justify-end">
            <base-button
                primary
                class="ml-4 flex-shrink-0"
                @click.stop="addToWaitlist(pet)"
            >Add to waitlist
            </base-button
            >
          </div>
        </td>
      </router-link>
      </tbody>
    </table>
  </div>
</template>

<script>
import Waitlist from "../../models/Waitlist";

export default {
  name: "pets-results-table",
  props: {
    pets: Array,
  },
  // data() {
  //     const initialData = {
  //         pet_id: 0
  //     }
  // },
  methods: {
    async addToWaitlist(pet) {
      // eslint-disable-next-line no-unused-vars
      const list = await Waitlist.add(pet);
    },
  },
};
</script>
