<template>
  <div>
    <div class="float-right clear-both mb-4">
      <slot name="actions"></slot>
    </div>
    <div
      class="bg-white border rounded-lg overflow-hidden shadow-lg"
      style="clear: both"
    >
      <div
        class="
          flex
          bg-mp-blue
          font-semibold
          py-4
          border-b
          items-center
          justify-center
        "
      >
        <h1
          v-if="title"
          class="font-semibold px-4 py-2 text-xl flex-1 text-white"
        >
          {{ title }}
        </h1>
        <slot name="search"></slot>
        <slot name="icon"></slot>
      </div>
      <div :class="{ 'p-4': !noPadding }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    noPadding: Boolean,
  },
};
</script>
<style scoped>
/*.header{*/
/*    background-color: #809abe;*/
/*}*/
</style>
