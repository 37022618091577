import Model from "./Model";
import Appointment from "./Appointment";
import axios from "axios";

export default class Pet extends Model {
    getRelationships() {
        return {
            appointments: Appointment,
        };
    }

    get icon() {
        switch (this.pet_type_id) {
            case 1:
                return "dog";
            case 2:
                return "cat";
            case 3:
                return "rabbit";
            case 4:
                return "hamster";
            case 5:
                return "horse";
            default:
                return "paw";
        }
    }

    static async getByCustomerId(customer_id) {
        const data = await window.Http.get("Pet?customer_id=", customer_id);
        return this.make(data);
    }

    static async getPetTypes() {
        const data = await window.Http.get('pet_types')
        return this.make(data);
    }

    static async create(form) {
        const data = await window.Http.post("pets", form);
        return this.make(data);
    }

    async exportData() {

        axios.get(process.env.VUE_APP_API_URL + "exports/" + this.id, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        })
            .then(response => {
                const url = response.data.url;
                const link = document.createElement('a');
                link.href = url;
                link.download = response.data.filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error('Error:', error));

    }

    static async find(id) {
        const data = await window.Http.get("/pets/" + id);
        return this.make(data);
    }

    static async findWithFlag(id, flag) {
        const data = await window.Http.get("/pets/" + id + "?" + flag);
        return this.make(data);
    }

    static async all() {
        const data = await window.Http.get("pets");
        return this.make(data);
    }

    async update(form) {
        const data = await window.Http.put("pets/" + this.id, form);
        return this.fill(data);
    }

    async delete() {
        const data = await window.Http.delete("pets/" + this.id);
        return this.fill(data);
    }
}