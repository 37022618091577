<template>
  <div>
    <base-panel no-padding>
      <template #search>
        <input
            class="ml-3 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal mr-5"
            v-model="search"
            type="text"
            placeholder="Name, email, phone, address, postcode, town, pet names..."
            @input="listAllCustomers()"
        />
      </template>
      <template #icon>
        <div
            id="add-customer-div"
            @click="router.push({ name: 'NewCustomer' })"
        >
          <add-customer-icon
              class="fill-current w-8 h-6 mr-3"
          ></add-customer-icon>
        </div>
      </template>
      <div
          v-if="loading"
          class="flex h-16 items-center justify-center font-semibold text-gray-600"
      >
        Loading...
      </div>
      <customers-results-table
          @getCustomers="listAllCustomers"
          :customers="customers"
          :search="search"
          v-else
      ></customers-results-table>
    </base-panel>
  </div>
</template>

<script>
import Customer from "../../models/Customer";
import CustomersResultsTable from "../../components/admin/CustomersResultsTable.vue";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {
    CustomersResultsTable,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      customers: [],
      loading: false,
      search: "",
    };
  },
  methods: {
    async listAllCustomers() {
      this.loading = true;
      try {
        this.customers = await Customer.getByQuery(this.search);
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.listAllCustomers();
  },
};
</script>

<style scoped>
#add-customer-div {
  cursor: pointer;
}
</style>
