<template>
    <form>
        <base-horizontal-field label="Animal" class="mt-2">
            <base-input v-model="pet.pet_type"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Name">
            <base-input v-model="pet.name"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Age">
            <base-input v-model="pet.age"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Sex">
            <base-toggle v-model="pet.sex" :options="[{text:'Male', value:'male'}, {text:'Female', value:'female'}]"></base-toggle>
        </base-horizontal-field>
        <base-horizontal-field label="Breed">
            <base-input v-model="pet.breed"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="DOB">
            <base-input v-model="pet.dob"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Main colour">
            <base-input v-model="pet.main_colour"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Spayed/Neutered">
            <base-toggle v-model="pet.spayed" :options="[{text:'Yes', value:true}, {text:'No', value:false}]"></base-toggle>
        </base-horizontal-field>
        <base-horizontal-field label="Microchip">
            <base-input v-model="pet.microchip"></base-input>
        </base-horizontal-field>
        <base-horizontal-field label="Distinguishing features">
            <base-input v-model="pet.distinguishing_features"></base-input>
        </base-horizontal-field>
    </form>
</template>

<script>
    export default {
        props: {
            title: String,
            noPadding: Boolean,
        },
    }
</script>
