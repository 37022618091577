//@ts-nocheck

import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router';
import App from "./App";
import auth from "./stores/auth";
import VCalendar from "v-calendar";
import VTooltip from "floating-vue";
import FloatingVue from "floating-vue";
import './assets/tailwind.css'
import routes from "@/router/routes";

window.Http = require("./services/Http").default;
window.Form = require("./services/Form").default;

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp(App)
app.use(router)
app.use(VTooltip)
app.use(FloatingVue)


const files = require.context("./components/shared", true, /\.(vue|js)$/i);
files.keys().map((key) =>
    app.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);

// const $auth = computed({
//   get() {
//     return auth.value
//   },
//   set(val) {
//     auth.value = val
//   }
// });
//
// const $petTypes = computed({
//   get() {
//     return petTypes.value
//   },
//   set(val) {
//     petTypes.value = val
//   }
// })

app.mixin({
    computed: {
        $auth() {
            return auth;
        },
    },
    methods: {
        formatDatetimeAsDate: function (date) {
            if (!date) return null;
            var date = new Date(date);

            if (!this.isValidDate(date)) return null;

            return (
                date.getFullYear() +
                "-" +
                (date.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                date
                    .getDate()
                    .toString()
                    .padStart(2, "0")
            );
        },
        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        },
    },
});

app.use(VCalendar, {
    componentPrefix: "vc",
});

app.mount('#app');