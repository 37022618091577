import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-643fce84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-gray-100 rounded flex overflow-hidden h-12" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.value,
        tabindex: "0",
        class: _normalizeClass(["flex-1 flex items-center justify-center cursor-pointer", 
        _ctx.selectedOption?.value === option.value
          ? 'selected-tab text-white'
          : 'hover:bg-gray-400 focus:bg-gray-400 text-gray-700'
      ]),
        onClick: ($event: any) => (_ctx.onOptionSelected(option))
      }, _toDisplayString(option.text), 11, _hoisted_2))
    }), 128))
  ]))
}