<template>
  <div>
    <p v-if="medications.length < 1" class="p-4">No medications found.</p>
    <table v-else class="w-full">
      <thead>
      <tr
          class="text-sm tracking-wider text-xl font-LexendDeca text-mp-blue text-left bg-mp-off-white"
      >
        <th class="font-medium p-4" style="width: 35%">Medication</th>
        <th class="font-medium p-4" style="width: 35%">Current Batch</th>
        <th class="font-medium p-4 w-px" style="width: 30%">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="rowClick(medication)"
          :key="medication.id"
          v-for="medication in medications"
          class="tablelink border-t cursor-pointer"
      >
        <td class="p-4">
          <div class="capitalize font-semibold text-lg">
            {{ medication.name || "Unknown" }}
          </div>
        </td>
        <td class="p-4">
          <div>
            {{
              medication.batches.length > 0
                  ? medication.batches[0].value
                  : "none"
            }}
          </div>
        </td>
        <td class="p-4">
          <div class="flex">
            <base-button
                primary
                @click.stop="add_batch(medication.id)"
                class="mt-4 mr-2 inline-flex items-center"
            >
              <span>Add batch</span>
            </base-button>
            <base-button
                primary
                @click.stop="showPriceModal(medication)"
                class="mt-4 inline-flex items-center"
            >
              <span>Update price</span>
            </base-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <add-medication-price
        v-show="showMedicationPriceModal"
        :modalHeadline="selectedMedication.name + ' - update price'"
        message="Are you sure?"
        :currentCost="currentCost"
        :currentRetail="currentRetail"
        @confirmPriceEvent="createNewPrice"
        @close="closePriceModal"
    >
    </add-medication-price>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Batch from "../../models/Batch";
import Toast from "../../services/Toast";
import AddMedicationPrice from "../modals/AddMedicationPrice.vue";
import Medication from "../../models/Medication";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  props: {
    medications: Array,
  },
  components: {
    AddMedicationPrice,
  },
  data() {
    return {
      active: false,
      currentCost: "0",
      currentRetail: "0",
      selectedMedication: {
        name: "unknown",
      },
      showMedicationPriceModal: false,
    };
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async rowClick(medication) {
      await this.router.push({
        name: 'MedicationSummary', params: {id: medication.id}
      });
    },
    async add_batch(medication_id) {
      const inputValue = "";

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'bg-mp-blue button-bkg text-white text-base font-semibold py-1 px-4 rounded mr-2',
          cancelButton: 'bg-gray-100 text-gray-700 hover:bg-gray-300 font-semibold py-1 px-4 rounded ml-2'
        },
        buttonsStyling: false
      })

      const value = await swalWithBootstrapButtons.fire({
        title: "Enter batch id",
        input: "text",
        inputValue: inputValue,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
      }).then((result) => {
        if (result.value) {
          const data = {
            medication_id: medication_id,
            value: result.value,
            active: true,
          };

          const batch = Batch.create(data);

          if (batch) {
            Toast.fire({
              icon: "success",
              title: "batch " + result.value + " created",
            });
          }

          this.$emit("add");
        }
      });
    },
    async showPriceModal(medication) {
      this.selectedMedication = medication;

      const med = await Medication.getByIdWithFlag(
          medication.id,
          "include=currentCostPrice,currentRetailPrice"
      );

      this.currentCost =
          med.currentCostPrice.length > 0
              ? med.currentCostPrice[0].value
              : "no price yet";
      this.currentRetail =
          med.currentRetailPrice.length > 0
              ? med.currentRetailPrice[0].value
              : "no price yet";

      this.showMedicationPriceModal = true;
    },
    closePriceModal() {
      this.showMedicationPriceModal = false;
    },
    async createNewPrice(data) {
      await Medication.createPrice(this.selectedMedication.id, data[0]);

      this.closePriceModal();
      await Toast.fire({
        icon: "success",
        title: "price updated successfully",
      });
    },
  },
};
</script>

<style scoped>
.tablelink:hover {
  color: #0074b5;
}
</style>
