<template>
    <base-panel title="Audit" no-padding>
        <template #search >
            <input class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-1 px-4 block w-full appearance-none leading-normal mr-5"
                   v-model="search"
                   type="text"
                   placeholder="Search by medication, batch...">
        </template>
        <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
        <pet-audit-table :pet="pet" :search="search" v-else></pet-audit-table>
    </base-panel>
</template>

<script>
    import Pet from '../../models/Pet'
    import PetAuditTable from "../../components/admin/PetAuditTable";
    import BasePanel from "../../components/shared/BasePanel";

    export default {
        props: {
          id: Number
        },
        components: {
            BasePanel,
            PetAuditTable
        },
        data() {
            return {
                pet: {},
                loading: false,
                search: ''
            }
        },
        methods: {
            async getPet() {
                this.loading = true
                try {
                    this.pet = await Pet.find(this.id, 'appointments')
                } catch(error){
                    await this.$auth.logout();
                    this.router.push({ name: "Login" });
                } finally {
                    this.loading = false
                }
            },
        },
        mounted() {

        },
        beforeMount(){
            this.getPet();
        },
    }
</script>

<style scoped>

</style>
