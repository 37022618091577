<template>
  <div>
    <base-panel class="mt-8" no-padding>
      <template #search>
        <input
          class="
            ml-3
            bg-white
            focus:outline-none focus:shadow-outline
            border border-gray-300
            rounded-lg
            py-2
            px-4
            block
            w-full
            appearance-none
            leading-normal
            mr-5
          "
          v-model="search"
          type="text"
          placeholder="Search email or microchip"
          @input="
            listMicrochipRegistrationRequests({ search, page: 1, perPage: 10 })
          "
        />
      </template>
      <template #icon>
        <div
          id="refresh-mc-history-page"
          @click="router.go(router.currentRoute)"
          v-tooltip="{
            content: 'Reset page details',
            placement: 'left',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            offset: 5,
            delay: {
              show: 1000,
              hide: 100,
            },
          }"
        >
          <svg
            class="w-10 h-10 mr-4 text-white cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
        </div>
      </template>
      <microchip-registration-history-table
        v-if="!loading"
        @listMicrochipRegistrationRequests="listMicrochipRegistrationRequests"
        :resultsSize="resultsSize"
        :requests="requests"
        :search="search"
      ></microchip-registration-history-table>
    </base-panel>
  </div>
</template>

<script>
import Microchip from "../models/Microchip";
import MicrochipRegistrationHistoryTable from "../components/admin/MicrochipRegistrationHistoryTable.vue";

export default {
  components: {
    MicrochipRegistrationHistoryTable,
  },
  data() {
    return {
      loading: true,
      requests: [],
      resultsSize: 0,
      search: "",
    };
  },
  methods: {
    async listMicrochipRegistrationRequests(payload) {
      this.data = await Microchip.listRegistrationRequests(
        payload.search ? payload.search : null,
        payload.page,
        payload.perPage
      );

      this.requests = this.data.data;
      this.resultsSize = this.data.meta.total;
    },
  },
  async beforeMount() {
    await this.listMicrochipRegistrationRequests({ page: 1, perPage: 10 });
    this.loading = false;
  },
};
</script>

<style >
</style>
