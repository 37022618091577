<template>
    <div>
        <multiselect
                @input="$emit('input', $event.target.value)"
                class="h-12 bg-gray-200 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
                :custom-label="name"
                placeholder="unknown"
                label="name"
                track-by="name"
        >
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                v-text="option.name"
                :selected="option.value === value"
                ></option>
        </multiselect >
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: { Multiselect },
        props: {
            value: null,
            label: String,
            options: Array
        },
        computed: {
            inputError () {
                return this.error ? 'border-red-500' : 'border-gray-200'
            }
        },
    }
</script>
