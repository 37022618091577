
//@ts-nocheck
import BasePanel from "../components/shared/BasePanel.vue";
import BaseHorizontalField from "../components/shared/BaseHorizontalField.vue";
import Multiselect from "vue-multiselect";
import Microchip from "../models/Microchip";
import Team from "../models/Team";
import Toast from "../services/Toast";
import axios from "axios";
import InputModal from "../components/modals/InputModal.vue";
import {useRouter} from "vue-router/dist/vue-router";
import TeamSettingService from "@/models/TeamSettingService";
import {debounce, isNil} from 'lodash';
import PhoneNumberService from "@/models/PhoneNumberService";
import Pet from "@/models/Pet";
import FlagDropdown from "@/components/shared/FlagDropdown.vue";
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/solid'
import FormService from "@/services/FormService";
import {v4 as uuidv4} from 'uuid';

export default {
  components: {FlagDropdown, BasePanel, BaseHorizontalField, Multiselect, InputModal, CheckCircleIcon, XCircleIcon},
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      microchip: "",
    };

    return {
      isLoading: false,
      address: "",
      addresses: [],
      createAnother: false,
      microchip: "",
      registrationBatch: uuidv4(),
      mobileSuffix: "",
      microchipValue: "",
      isoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          "isoCountryCode": "GB",
          "prefix": "+44"
        },
        {
          "isoCountryCode": "IE",
          "prefix": "+353"
        }
      ],
      petTypes: [],
      petTypeOptionsArray: [],
      features: [],
      petTypeId: null,
      showAddBatchButton: false,
      breed: "",
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      pinpointApiLoading: false,
      topLevelSettings: {
        email: true,
        mobile: true,
      },
      petBreeds: [],
      registeredPetIds: 0,
      registeredPets: [],
      cantFindChip: false,
      isLostChipModalVisible: false,
      form: new window.Form(initialData, async (form) => {
        this.isLoading = true;

        form.microchip = this.microchip.name;
        form.breed = this.breed.name;

        if ((!!form.email && form.email.length === 0) || form.email === '') {
          delete form.email;
        }

        const prefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;
        if (form.mobile === prefix) {
          //consider this empty input
          form.mobile = null;
        }

        if (this.petTypeId > 0) {
          form.pet_type_id = this.petTypeId;
        }

        if (form.pet_type_id) {
          const petType = this.petTypes.filter(function (i) {
            return i.id == form.pet_type_id;
          });

          form.pet_type = petType[0].value;
          form.pet_type_id = parseInt(form.pet_type_id);
        }

        if (this.createAnother) {
          form.batch = this.registrationBatch;
        }

        await Microchip.register(form);

        this.isLoading = false;

        Toast.fire({
          timer: 3000,
          icon: "success",
          title: "microchip registration complete! We will email/text the owner.",
        });

        if (this.createAnother) {
          //add pet to registered table
          this.registeredPetIds++;
          this.registeredPets.push({
            id: this.registeredPetIds,
            name: this.form.pet_name,
            microchip: this.form.microchip,
          });

          await this.resetPage();
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));


        if (this.createAnother) {
          await this.resetPage();
          window.scrollTo(0, 0);
        } else {
          this.router.go(this.router.currentRoute);
        }
      }),
      microchips: [],
      showUserInfo: false,
      showPetInfo: false,
      maxlength: "",
    };
  },
  async beforeMount() {
    await this.getFeatures();
    await this.getSettings();
    await this.setCountryMobilePhoneCode();
    this.petTypes = await Pet.getPetTypes();
    this.petTypeOptionsArray = await this.petTypeOptions();
  },
  methods: {
    setIsoCountryCode(event) {
      const oldPrefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;
      const suffix = this.form.mobile.slice(oldPrefix.length);

      this.isoCountryCode = event.target.value;

      const newPrefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;
      this.form.mobile = newPrefix + suffix;

      this.setCountryMobilePhoneCode();
    },
    async petTypeOptions() {
      const parsedPetTypes = this.petTypes.map((petType) => ({
        value: petType.id,
        name: petType.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetTypes];
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;

      if (!this.form.mobile || ["+44", "+353"].includes(this.form.mobile)) {
        this.form.mobile = prefix;
      }

      let result: Array<string> = [...this.form.mobile];

      for (let i = 0; i < prefix.length; i++) {
        const c: string = prefix.charAt(i);
        const t: string = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }

      this.form.mobile = result.join('');
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
    },
    async focusMobileNumberInput(event: Event) {
      FormService.setCursorPosition(event.target, this.form.mobile.length);
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      await this.setCountryMobilePhoneCode();

      if (this.form.mobile && this.form.mobile.length < 8) {
        return;
      }

      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity = await PhoneNumberService.validatePhoneNumber(this.form.mobile, "mobile");

      if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
      } else {
        this.mobilePhoneNumberIsInvalid = true;
      }

      this.pinpointApiLoading = false;
    },
    async finish() {
      await Microchip.completeBatchRegistration({batch: this.registrationBatch});
      this.router.go(this.router.currentRoute);
    },
    async clear() {
      this.addresses = [];
      this.form.email = "";
      this.form.user_first_name = "";
      this.form.user_last_name = "";
      this.form.phone = "";
      this.breed = "";
      this.form.dob = "";
      this.form.distinguishing_features = "";
      this.form.address_line_1 = "";
      this.form.address_line_2 = "";
      this.form.postcode = "";
      this.form.town = "";
      this.form.address_line_3 = "";
      this.form.address_line_4 = "";
      this.form.spayed = "";
      this.petTypeId = null;
      this.form.mobile = null;
      this.address = "";
      this.registeredPets = [];
      this.form.reset();
      await this.resetMobileSettings();
    },
    addTag(newTag) {
      const tag = {
        value: newTag,
        id: 7000,
      };
      this.petBreeds.push(tag);
      this.breed = {value: 7000, name: newTag};
    },
    async resetPage() {
      delete this.form.pet_name;
      delete this.form.colour;
      delete this.form.distinguishing_features;
      this.microchip = "";
      this.microchipValue = "";
      await this.searchMicrochips("");
    },
    async getPetBreeds() {
      let url = "pet_breeds";

      if (this.petTypeId > 0) {
        url += "?pet_type_id=" + this.petTypeId;
      }

      this.petBreeds = await window.Http.get(url);
    },
    handleKeyDown(event: KeyboardEvent, type: string) {
      if (type === 'email' && !(this.topLevelSettings.mobile && this.topLevelSettings.email)) {
        FormService.handleKeyboardEvent(event, ["Enter", "Tab"], this.toggleShowUserInfo);
      }

      if (type === 'mobile') {
        FormService.handleKeyboardEvent(event, ["Enter", "Tab"], this.toggleShowUserInfo);
      }
    },
    toggleShowUserInfo() {
      this.showUserInfo = true;
    },
    toggleShowPetInfo() {
      this.showPetInfo = true;
    },
    async lostChip(value) {
      this.sendEmailForLostChip(value);
      this.closeLostChipModal();
    },
    showLostChipModal() {
      this.isLostChipModalVisible = true;
    },
    closeLostChipModal() {
      this.isLostChipModalVisible = false;
    },
    async listAddresses() {
      if (!this.form.postcode) return;
      const response = await axios.get(
          "https://api.getaddress.io/find/" +
          this.form.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );
      this.addresses = response.data.addresses;
    },
    async getSettings() {
      const settingEntity = await TeamSettingService.getTeamSettings();

      this.topLevelSettings.email = settingEntity?.settings?.microchip_registration_settings?.top_level_registration_fields?.email ?? true;
      this.topLevelSettings.mobile = settingEntity?.settings?.microchip_registration_settings?.top_level_registration_fields?.mobile ?? true;
    },
    async getFeatures() {
      this.features = await Team.getFeatures();

      if (this.features.includes("batch_management")) {
        this.showAddBatchButton = true;
      }
    },
    populateAddressFields() {
      if (this.form.address) {
        this.form.address_line_1 = this.address.line_1;
        this.form.address_line_2 = this.address.line_2;
        this.form.address_line_3 = this.address.line_3;
        this.form.address_line_4 = this.address.line_4;
      }
    },
    populateMicrochipOptions(value) {
      this.microchipValue = value;
      if (value && value.length > 0) {
        this.searchMicrochips(value);
      }
    },
    async sendEmailForLostChip(value) {
      await Microchip.sendEmailForLostChip(value);

      Toast.fire({
        timer: 2000,
        icon: "success",
        title: "Thank you! We will get back to you as soon as we can.",
      });

      await new Promise((resolve) => setTimeout(resolve, 2000));

      location.reload();
    },
    async searchMicrochips(query) {
      try {
        this.microchips = await Microchip.search(query);
        if (this.microchips.length == 0) {
          this.cantFindChip = true;
        } else {
          this.cantFindChip = false;
        }
      } finally {
      }
    },
  },
  mounted() {
    this.searchMicrochips();
    // this.$el.addEventListener('click', this.changeBorder);
  },
  watch: {
    address: function () {
      const _address = this.addresses[this.address];

      if (_address) {
        this.form.address_line_1 = _address.line_1;
        this.form.address_line_2 = _address.line_2;
        this.form.address_line_3 = _address.line_3;
        this.form.address_line_4 = _address.line_4;
        this.form.town = _address.town_or_city;
      }
    },
    petTypeId() {
      this.getPetBreeds();
    },
  },
  computed: {
    formSubmitEnabled() {
      return !!this.microchip && (this.form.email || this.mobilePhoneNumberIsValid);
    },
    createButtonTooltipText() {
      if (!!this.formSubmitEnabled) {
        return '';
      }

      if (this.topLevelSettings.mobile && this.topLevelSettings.email) {
        return "Microchip & email/mobile are required.";
      }

      if (this.topLevelSettings.mobile) {
        return "Microchip & mobile is required.";
      }

      if (this.topLevelSettings.email) {
        return "Microchip & email is required.";
      }
    },
    addressOptions() {
      const parsedAddresses = [];

      for (let index = 0; index < this.addresses.length; ++index) {
        let value = this.addresses[index];

        parsedAddresses.push({value: index, name: value.line_1});
      }

      return [
        {value: null, name: "Please select address"},
        ...parsedAddresses,
      ];
    },
    microchipOptions() {
      const parsedMicrochips = this.microchips.map((m) => ({
        value: m.id,
        name: m.value,
      }));

      // return [{ value: null, name: "Unknown" }, ...parsedMicrochips];
      return parsedMicrochips;
    },
    isInvalidMicrochip() {
      return this.microchip == "";
    },
    microchipOptionsClass() {
      if (this.form.errors.get("microchip") && this.microchip == "") {
        return "microchip-multi-select w-full invalid";
      }
      return "microchip-multi-select w-full";
    },
    petBreedOptions() {
      const parsedPetBreeds = this.petBreeds.map((petBreed) => ({
        value: petBreed.id,
        name: petBreed.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetBreeds];
    },
  },
};
