<template>
  <div class="Waitlist_table">
    <div
        v-if="loading"
        class="flex h-16 items-center justify-center text-gray-600"
    >
      Loading...
    </div>
    <p v-if="waitlist.length < 1" class="p-4">No pets found.</p>
    <table v-else class="w-full">
      <thead>
      <tr
          class="text-sm tracking-wider text-xl font-LexendDeca text-mp-blue text-left bg-mp-off-white"
      >
        <th style="width: 15%" class="font-medium p-4">Position</th>
        <th style="width: 25%" class="font-medium p-4">Pet</th>
        <th style="width: 25%" class="font-medium p-4">Customer</th>
        <th style="width: 35%"></th>
      </tr>
      </thead>
      <tbody>
      <tr
          @click="rowClick(item)"
          :key="item.id"
          v-for="(item, index) in waitlist"
          class="tablelink border-t cursor-pointer"
      >
        <td class="p-4">
          <div class="capitalize font-semibold text-lg">
            {{ index + 1 }}
          </div>
        </td>
        <td class="p-4">
          <div class="capitalize">
            {{ item.pet.name }}
          </div>
        </td>
        <td class="p-4">
          <div class="capitalize">
            {{ item.customer_first_name }} {{ item.customer_last_name }}
          </div>
        </td>
        <td class="p-4">
          <div class="flex">
            <base-button
                primary
                @click.stop="createAppointment(item)"
                class="mr-4 inline-flex items-center bg-mp-blue hover:bg-mp-hover-blue text-mp-off-white"
            >
              <plus-circle-icon
                  icon="plus-circle"
                  class="fill-current w-4 h-4 mr-2"
              ></plus-circle-icon>
              <span>Add Appointment</span>
            </base-button>
            <base-button
                primary
                @click.stop="removePet(item)"
                class="inline-flex items-center"
            >
              <minus-circle-icon
                  icon="minus-circle"
                  class="fill-current w-4 h-4 mr-2"
              ></minus-circle-icon>
              <span>Remove</span>
            </base-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Waitlist from "../../models/Waitlist";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  props: {
    waitlist: Array,
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async rowClick(item) {
      await this.router.push({
        name: 'PetSummary', params: {id: item.pet.id}
      })
    },
    async createAppointment(item) {
      this.router.push({
        name: "NewAppointment",
        params: {pet_id: item.pet_id},
      });
    },
    async removePet(item) {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove pet from waitlist",
      });

      if (!result.value) {
        return;
      }

      await Waitlist.delete(item.pet_id);
      this.$emit("delete");
    },
  },
};
</script>

<style scoped>
.tablelink:hover {
  color: #0074b5;
}

.Waitlist_table {
  overflow: auto;
}
</style>
