<template>
    <div>
        <table class="w-full">
            <tbody>
            <tr v-if="$auth.role('admin')" @click="router.push({params: {id: $auth.user().team_id}, name: 'AccountSettings'})">
                <td class="p-4">
                    <div class="capitalize font-semibold text-lg">
                        Account Settings
                    </div>
                </td>
            </tr>
            <tr @click=" router.push({params: {id: $auth.user().id}, name: 'ChangePassword'})" class="p-4">
                <td class="p-4">
                    <div class="capitalize font-semibold text-lg">
                        Change password
                    </div>
                </td>
            </tr>
            <tr v-if="$auth.role('admin')" @click="router.push({  name: 'Medications' })" class="p-4">
                <td class="p-4">
                    <div class="capitalize font-semibold text-lg">
                        Medications
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import {useRouter} from "vue-router/dist/vue-router";

    export default {
        props: {
            pets: Array,
        },
      setup() {
        const router = useRouter();

        return {
          router
        }
      }
    }
</script>

<style scoped>
    tr {
        cursor: pointer;
    }

    td:hover {
        color: #0074b5;
    }
</style>
