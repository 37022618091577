import Model from "./Model";

export default class Team extends Model {
  static async getById(id) {
    const data = await window.Http.get("teams/" + id);
    return this.make(data);
  }

  static async getVisitorRelevantDataById(id) {
    const data = await window.Http.get("visitor/teams/" + id);
    return this.make(data);
  }

  static async getFeatures() {
    return await window.Http.get("features");
  }

  async update(form) {
    const data = await window.Http.put("teams/" + this.id, form);
    return this.fill(data);
  }
}
