<template>
  <div>
    <base-horizontal-field label="First name" class="mt-2">
      <base-input
          input-class="capitalize"
          v-model="form.first_name"
          :error="form.errors.get('first_name')"
          @input="form.errors.clear('first_name')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Last name">
      <base-input
          input-class="capitalize"
          v-model="form.last_name"
          :error="form.errors.get('last_name')"
          @input="form.errors.clear('last_name')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Email">
      <base-input
          v-model="form.email"
          :error="form.errors.get('email')"
          @input="form.errors.clear('email')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Phone number">
      <base-input
          v-model="form.home_number"
          :error="form.errors.get('home_number')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Mobile number">
      <base-input
          v-model="form.mobile_number"
          :error="form.errors.get('mobile_number')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Postcode">
      <div class="flex justify-between items-center justify-center">
        <base-input class="mr-4" v-model="form.postcode" :error="form.errors.get('postcode')"></base-input>
        <base-button
            @click="listAddresses()"
            class="h-12"
            green
        >
          <p class="mt-2">Find Address</p></base-button
        >
      </div>
    </base-horizontal-field>
    <base-horizontal-field :class="{ hidden: !addresses.length }">
      <base-select
          v-model="address"
          :options="addressOptions"
          label="select address"
      ></base-select>
    </base-horizontal-field>
    <base-horizontal-field label="Address line 1">
      <base-input v-model="form.address_line_1"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Address line 2">
      <base-input v-model="form.address_line_2"></base-input>
    </base-horizontal-field>
    <base-horizontal-field
        v-if="form.address_line_3"
        label="Address line 3"
        class="mt-2"
    >
      <base-input
          :error="form.errors.get('address_line_3')"
          v-model="form.address_line_3"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field
        v-if="form.address_line_4"
        label="Address line 4"
        class="mt-2"
    >
      <base-input
          :error="form.errors.get('address_line_4')"
          v-model="form.address_line_4"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Town">
      <base-input v-model="form.town"></base-input>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button
            primary
            @click="form.submit()"
            class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
        >Create
        </base-button
        >
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import Customer from "../../models/Customer";
import axios from "axios";
import BaseSelect from "../shared/BaseSelect";
import BaseHorizontalField from "../shared/BaseHorizontalField";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {BaseHorizontalField, BaseSelect},
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      first_name: "",
      last_name: "",
      email: "",
      home_number: "",
      mobile_number: "",
      postcode: "",
      door_number: "",
      address_line_1: "",
      address_line_2: "",
      town: "",
    };

    return {
      postcode: "",
      address: "",
      addresses: [],
      address_line_1: "",
      address_line_2: "",
      town: "",
      form: new window.Form(initialData, async (form) => {
        const customer = await Customer.create(form);
        await this.router.push({
          name: "NewPet",
          params: {customer_id: customer.id},
        });
      }),
    };
  },
  methods: {
    async listAddresses() {
      if (!this.form.postcode) return;
      const response = await axios.get(
          "https://api.getaddress.io/find/" +
          this.form.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );
      this.addresses = response.data.addresses;
    },
  },
  watch: {
    address: function () {
      const _address = this.addresses[this.address];

      if (_address) {
        this.form.address_line_1 = _address.line_1;
        this.form.address_line_2 = _address.line_2;
        this.form.address_line_3 = _address.line_3;
        this.form.address_line_4 = _address.line_4;
        this.form.town = _address.town_or_city;
      }
    },
  },
  computed: {
    addressOptions() {
      const parsedAddresses = [];

      for (let index = 0; index < this.addresses.length; ++index) {
        let value = this.addresses[index];

        parsedAddresses.push({value: index, name: value.line_1});
      }

      return [
        {value: null, name: "Please select address"},
        ...parsedAddresses,
      ];
    },
  },
};
</script>

<style scoped>
</style>
