
//@ts-nocheck
import 'v-calendar/dist/style.css';
import Pet from "../../models/Pet";
import Appointment from "../../models/Appointment";
import BaseHorizontalField from "../shared/BaseHorizontalField";
import BaseWideHorizontalField from "../shared/BaseWideHorizontalField";
import Multiselect from "vue-multiselect";
import Medication from "../../models/Medication";
import Waitlist from "../../models/Waitlist";
import ConfirmDelete from "../modals/ConfirmDelete";
import {useRouter} from "vue-router/dist/vue-router";
import PhotoSwipeLightbox from "photoswipe/lightbox";

export default {
  props: {
    pet_id: Number,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  components: {
    BaseHorizontalField,
    BaseWideHorizontalField,
    Multiselect,
    ConfirmDelete,
  },
  data() {
    const initialData = {
      pet_id: this.pet_id,
      service: "no_consult",
      occurrence: new Date().toISOString(),
      data: {},
    };
    return {
      selectedItem: Object,
      isConfirmDeleteModalVisible: false,
      showBase64Images: false,
      base64ImageObjects: [],
      images: [],
      image: "",
      submitting: false,
      savedImages: [],
      value: [],
      date: new Date(),
      discount: 0,
      pet: Object,
      medicationObject: {
        name: "unknown",
        quantity: 0,
        costPerItem: 0,
        totalCost: 0,
      },
      medicationRows: [],
      medications: [],
      form: new window.Form(initialData, async (form) => {
        form.medications = this.medicationRows;
        form.data = {totalCost: this.totalCost, discount: this.discount};
        form.images = this.savedImages;

        this.submitting = true;

        await Appointment.create(form);

        this.removePetFromWaitlist();

        await this.router.push({
          name: "PetSummary",
          params: {id: this.pet_id},
        });
      }),
    };
  },

  methods: {
    async deleteImage() {
      const i = this.selectedItem;

      this.savedImages = this.savedImages.filter(function (obj) {
        return obj.uuid !== i.uuid;
      });
      await Appointment.removeImage(this.selectedItem.id);

      this.closeConfirmDeleteModal();
    },
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    async removeImage(item) {
      this.selectedItem = item;
      this.showConfirmDeleteModal();
    },
    async onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      await this.createImage(files[0]);
    },
    async createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;

        var base64imageObject = {
          base64code: e.target.result,
          filename: file.name,
          id: file.name,
        };

        vm.base64ImageObjects.push(base64imageObject);
        vm.uploadImage(base64imageObject);
      };
      reader.readAsDataURL(file);
    },
    async uploadImage(base64imageObject) {
      const uploadedImage = await Appointment.uploadBase64Image(
          base64imageObject
      );

      this.savedImages.push(uploadedImage);

      this.savedImages = this.fixImagesForGallery(this.savedImages);

      console.log(this.savedImages);
    },
    async removeImage(item) {
      this.selectedItem = item;
      this.showConfirmDeleteModal();
    },
    async getPet() {
      this.pet = await Pet.findWithFlag(this.pet_id, "appointments");
    },
    async getMedications() {
      const result = await Medication.getAvailableWithPrice();
      this.medications = result.data.filter((m) => m.batches.length > 0);
    },
    addMedicationComponent() {
      const med = {
        id: this.medicationRows.length + 1,
        quantity: 1,
        costPerItem: "0.00",
        totalCost: "0.00",
      };

      this.medicationRows.push(med);
    },
    removeMedicationComponent(index: number) {
      this.medicationRows.splice(index);
    },
    getTotalCost() {
      this.medicationRows = this.medicationRows.map(function (m) {
        return {
          ...m,
          totalCost: parseFloat(m.quantity) * parseFloat(m.costPerItem),
        };
      });
    },
    getTotalAppointmentCost() {
      let totalCost = 0;
      this.medicationRows.map(function (m) {
        totalCost += parseFloat(m.totalCost);
      });

      return totalCost;
    },
    async selectMedicationOption(med) {
      const medFromList = await this.medications.filter(
          (m) => m.id === med.name.value
      )[0];

      const medFromRows = this.medicationRows.filter((m) => m.id === med.id)[0];
      const medFromRowsIndex = this.medicationRows.findIndex(
          (m) => m.id === med.id
      );

      const newMed = {
        id: med.id,
        name: {
          value: medFromRows.name.value,
          name: medFromRows.name.name,
        },
        quantity: 1,
        costPerItem:
        medFromList.currentRetailPrice[
        medFromList.currentRetailPrice.length - 1
            ].value,
        totalCost:
        medFromList.currentRetailPrice[
        medFromList.currentRetailPrice.length - 1
            ].value,
      };

      this.medicationRows[medFromRowsIndex] = newMed;
    },
    // removeItem(index) {
    //   var element = document.getElementById(index);
    //   element.remove(element);
    // },
    medicationIds() {
      const parsedMedicationIds = this.value.map((med) => med.value);

      return parsedMedicationIds;
    },
    async removePetFromWaitlist() {
      await Waitlist.delete(this.pet_id);
      this.$emit("delete");
    },
    fixImagesForGallery(images) {
      return images.map(function (i) {
        return {
          id: i.id,
          uuid: i.uuid,
          largeURL: i.url ?? i.largeURL,
          thumbnailURL: i.url ?? i.thumbnailURL,
          height: 400,
          width: 400,
        };
      });
    },
  },
  computed: {
    medicationOptions() {
      const parsedMedications = this.medications.map((med) => ({
        value: med.id,
        name: med.name,
      }));

      return [{value: null, name: "Unknown"}, ...parsedMedications];
    },
    totalCost() {
      let consultPrice = 0;

      if (this.form.service === "follow_up_consult") {
        consultPrice = 15;
      } else if (this.form.service === "consult") {
        consultPrice = 30;
      }

      return parseFloat(
          consultPrice + this.getTotalAppointmentCost() - this.discount
      ).toFixed(2);
    },
  },
  beforeMount() {
    this.form.service = "consult";
    this.getPet();
    this.getMedications();
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + 'appointment-create-gallery',
        children: 'a',
        pswpModule: () => import('photoswipe'),
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
};
