import Model from './Model'

export default class Waitlist extends Model {
    getRelationships () {
        return {
            //
        }
    }

    static async create (item) {
        const data = await window.Http.post('waitlists', item)
        return data;
    }

    static async all () {
        const data = await window.Http.get('waitlists')
        return this.make(data)
    }

    static async add (pet) {
        const data = await window.Http.post('/waitlists', pet)
        return this.make(data)
    }

    static async delete (id) {
        const data = await window.Http.delete('waitlists/'+id)
        return this.make(data)
    }
}
