<template>
    <base-panel title="Add Microchips" class="add-microchips-panel">
        <div class="flex font-LexendDeca text-mp-blue">
            <div class="flex items-center text-mp-blue cursor-pointer back-btn">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                >
                    <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                    />
                </svg>
                <p
                        class="ml-1"
                        @click="router.push({ name: 'MicrochipRegistration' })"
                >
                    Back to microchip registration
                </p>
            </div>
        </div>

        <base-horizontal-field label="Batch number" class="mt-2">
            <div
                    class="sm:w-1/3 sm:text-right sm:mr-8 font-semibold mb-2 sm:mb-0"
            ></div>
            <div class="sm:w-2/3 sm:mr-6 md:mr-20 mb-2">
                <p class="text-mp-red cursor-pointer" @click="showLostBatchModal">
                    Can't find batch or box number?
                </p>
            </div>
            <multiselect
                    v-model="batch"
                    :options="batchOptions"
                    @search-change="populateBatchOptions"
                    @select="batchSelected"
                    @remove="removeBatch"
                    label="name"
                    track-by="name"
                    :multiple="false"
                    :preserve-search="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    class="microchip-multi-select w-full"
            ></multiselect>
        </base-horizontal-field>

        <base-horizontal-field v-if="boxes.length == 0" label="" class="mt-2">
            <div class="flex justify-center mb-6">
                <p class="font-LexendDeca text-mp-green">
                    Select the image below to find the location of batch & box number
                </p>
            </div>
            <div class="flex justify-center">
                <div id="microchip-box-gallery" class="grid grid-cols-3 gap-4">
                    <a
                            href="https://s3.eu-west-2.amazonaws.com/media.mypethq.io/box-batch-number.jpg"
                            data-pswp-width="600"
                            data-pswp-height="600"
                    >
                        <div class="image-container">
                            <img
                                    src="https://s3.eu-west-2.amazonaws.com/media.mypethq.io/box-batch-number.jpg"
                                    class="w-40 h-auto rounded-md"
                            />
                        </div>
                    </a>
                </div>
            </div>
            <div class="flex justify-center mt-4">
                <h1 class="font-LexendDeca text-mp-blue">
                    Boxes will appear here after a batch is selected...
                </h1>
            </div>
        </base-horizontal-field>

        <base-horizontal-field
                v-if="boxes.length > 0 && batch"
                label=""
                class="mt-2"
        >
            <div v-if="boxes.length > 0" class="flex justify-center h-8">
                <h1 class="font-LexendDeca text-mp-blue">
                    Please select your boxes from batch {{ batch.name }} below
                </h1>
            </div>
        </base-horizontal-field>
        <base-horizontal-field v-if="boxes.length > 0" label="" class="mt-2">
            <table
                    class="w-full bg-white border-solid border-2 border-light-blue-500"
            >
                <thead>
                <tr
                        class="
              text-sm
              tracking-wider
              text-xl
              font-LexendDeca
              text-mp-blue text-left
              bg-mp-off-white
            "
                >
                    <th class="font-medium p-4">
                        <label class="inline-flex items-center mt-3">
                            <i class="form-icon"></i>
                        </label>
                    </th>
                    <th class="font-medium p-4">Batch</th>
                    <th class="font-medium p-4">Box #</th>
                    <th class="font-medium p-4">Size</th>
                    <th class="font-medium p-4">Microchips</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        :key="box.id"
                        v-for="box in boxes"
                        class="tablelink border-t cursor-pointer"
                >
                    <td class="p-4">
                        <input
                                type="checkbox"
                                :value="box.id"
                                v-model="selected"
                                class="form-checkbox h-5 w-5 text-mp-blue"
                        />
                    </td>
                    <td class="p-4">
                        <div>
                            {{ box.batch }}
                        </div>
                    </td>
                    <td class="p-4">
                        <div>
                            {{ box.value }}
                        </div>
                    </td>
                    <td class="p-4">
                        <div>
                            {{ box.size }}
                        </div>
                    </td>
                    <td class="p-4">
                        <div>
                            <button
                                    v-tooltip="{
                    html:true,
                    content: getMicrochipsForBox(box),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    offset: 25,
                    delay: {
                      show: 200,
                      hide: 200,
                    },
                  }"
                            >
                                {{ getFirstMicrochipForBox(box) }}
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <base-button
                    primary
                    :class="addBatchesButtonClass"
                    @click="addBatches"
                    :disabled="addBatchesButtonIsDisabled"
            >
                <span>Add selected batches</span>
            </base-button>
        </base-horizontal-field>
        <input-modal
                v-show="isLostBatchModalVisible"
                :modalHeadline="'Check a missing batch/box'"
                message="Enter the batch/box you want to check (and a message if you like!), and we will look it up for you"
                value=""
                @confirmationEvent="lostBatch"
                @close="closeLostBatchModal"
        ></input-modal>
    </base-panel>
</template>

<script>
import BasePanel from "../components/shared/BasePanel.vue";
import BaseHorizontalField from "../components/shared/BaseHorizontalField.vue";
import Multiselect from "vue-multiselect";
import Microchip from "../models/Microchip";
import Toast from "../services/Toast";
import InputModal from "../components/modals/InputModal.vue";
import {useRouter} from "vue-router/dist/vue-router";
import PhotoSwipeLightbox from "photoswipe/lightbox";

export default {
    components: {BasePanel, BaseHorizontalField, Multiselect, InputModal},
    setup() {
        const router = useRouter();

        return {
            router
        }
    },
    data() {
        return {
            batch: "",
            batchValue: "",
            batches: [],
            boxes: [],
            dropdownOpen: false,
            isLostBatchModalVisible: false,
            images: [
                {
                    src: "https://s3.eu-west-2.amazonaws.com/media.mypethq.io/box-batch-number.jpg",
                    description: "Batch/Box label image",
                    alt: "Batch images",
                    thumbnailWidth: "220px",
                    thumbnail:
                        "https://s3.eu-west-2.amazonaws.com/media.mypethq.io/box-batch-number.jpg",
                },
            ],
            selected: [],
        };
    },
    methods: {
        async addBatches() {
            await Microchip.transferBoxesToTeam(this.batch.name, this.selected);

            Toast.fire({
                timer: 3000,
                icon: "success",
                title:
                    "Microchip batch registration complete! We have added these boxes to your team account.",
            });

            await new Promise((resolve) => setTimeout(resolve, 3000));

            location.reload();
        },
        async searchBatches(query) {
            try {
                this.batches = await Microchip.searchBatches(query);
            } finally {
            }
        },
        async removeBatch(query) {
            this.batch = "";
            this.boxes = [];
        },
        getFirstMicrochipForBox(box) {
            let microchips = box.microchips.map(function (m) {
                return m.value;
            })[0];

            return microchips;
        },
        async lostBatch(value) {
            this.sendEmailForLostBatch(value);
            this.closeLostBatchModal();
        },
        showLostBatchModal() {
            this.isLostBatchModalVisible = true;
        },
        closeLostBatchModal() {
            this.isLostBatchModalVisible = false;
        },
        async sendEmailForLostBatch(value) {
            await Microchip.sendEmailForLostBatch(value);

            Toast.fire({
                timer: 2000,
                icon: "success",
                title: "Thank you! We will get back to you as soon as we can.",
            });

            await new Promise((resolve) => setTimeout(resolve, 2000));

            location.reload();
        },
        getMicrochipsForBox(box) {
            let microchips = box.microchips
                .map(function (m) {
                    return m.value;
                })
                .join("<br>");

            return microchips;
        },
        async batchSelected(value) {
            this.boxes = await Microchip.getBoxesByBatch(value.name);
        },
        populateBatchOptions(value) {
            this.batchValue = value;
            if (value && value.length > 0) {
                this.searchBatches(value);
            }
        },
        removeDuplicateObjectFromArray(array, key) {
            var check = new Set();
            return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
        },
    },
    mounted() {
        this.searchBatches();

        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#' + 'microchip-box-gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
            });
            this.lightbox.init();
        }
    },
    unmounted() {
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    computed: {
        batchOptions() {
            const parsedBatches = this.batches.map((m) => ({
                value: m.id,
                name: m.batch,
            }));

            const filtered = this.removeDuplicateObjectFromArray(
                parsedBatches,
                "name"
            );

            return filtered;
        },
        addBatchesButtonIsDisabled() {
            return this.selected == 0;
        },
        addBatchesButtonClass() {
            if (this.addBatchesButtonIsDisabled) {
                return "mt-8 mb-4";
            }
            return "mt-8 mb-4 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue";
        },
    },
};
</script>

<style>
.microchip-multi-select .multiselect__tags {
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.microchip-multi-select .multiselect__single {
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.add-microchips-panel {
    height: 800px;
}

.tooltip-inner {
    background: #487b3c;
    color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    max-width: 250px;
}

.tooltip-arrow {
    border-color: #487b3c;
}
</style>
