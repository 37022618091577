//@ts-nocheck
import axios from "axios";
import Form from "./Form";

axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
};

// axios.interceptors.response.use(response => response, error => {
//     if (error.response === undefined) {
//         return Promise.reject(error)
//     }
//
//     switch (error.response.status) {
//         case 401:
//             window.axios.get('/logout')
//             modalStore.open('session-expired')
//             break
//
//         case 402:
//             window.location = '/settings#/subscription'
//             break
//     }
//
//     return Promise.reject(error)
// })

export default {
    async get(url: string, config = {}) {
        return this.send("get", url, undefined, config);
    },

    async post(url, data = {}, config = {}) {
        return this.send("post", url, data, config);
    },

    async put(url, data = {}, config = {}) {
        return this.send("put", url, data, config);
    },

    async patch(url, data = {}, config = {}) {
        return this.send("patch", url, data, config);
    },

    async delete(url, data = {}, config = {}) {
        return this.send("delete", url, data, config);
    },

    async send(method, url, data = undefined, config = {}) {
        if (data instanceof Form) {
            data = data.export();
        }

        const response = await axios({
            method,
            url,
            data,
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: true,
            ...config,
        });

        return response.data;
    },
};
