<template>
  <base-panel title="Pets" id="customer-pets-table" no-padding>
    <template #actions>
      <base-button primary @click="router.push({ name: 'NewPet', params: { customer_id: customer.id } })"
                   class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">
        <plus-circle-icon class="fill-current w-4 h-4 mr-2"></plus-circle-icon>
        <span>Add pet</span>
      </base-button>
    </template>
    <p
        v-if="customer.pets.length < 1"
        class="px-6 mb-4 mt-4 empty-table"
    >
      No pets found for this customer.
    </p>
    <table class="w-full" v-else>
      <tbody>
      <router-link
          tag="tr"
          v-for="pet in customer.pets"
          :key="pet.id"
          :to="{ name: 'PetSummary', params: { id: pet.id } }"
          class="hover:bg-gray-100 cursor-pointer"
      >
        <td class="p-4 w-px">
          <dog-icon v-if="pet.icon === 'dog'" class="fill-current w-5"></dog-icon>
          <cat-icon v-if="pet.icon === 'cat'" class="fill-current w-5"></cat-icon>
          <rabbit-icon v-if="pet.icon === 'rabbit'" class="fill-current w-5"></rabbit-icon>
          <hamster-icon v-if="pet.icon === 'hamster'" class="fill-current w-5"></hamster-icon>
          <horse-icon v-if="pet.icon === 'horse'" class="fill-current w-5"></horse-icon>
          <paw-icon v-if="pet.icon === 'paw'" class="fill-current w-5"></paw-icon>
        </td>
        <td class="p-4 pl-0 text-lg">
          {{ pet.name }}
          <span
              v-if="pet.breed"
              class="inline-block ml-2 bg-gray-200 rounded border border-gray-400 text-sm px-2"
              v-text="pet.breed"
          ></span>
        </td>
      </router-link>
      </tbody>
    </table>
  </base-panel>
</template>

<script>
import PlusCircleIcon from "@/components/shared/icons/PlusCircleIcon";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {PlusCircleIcon},
  props: {
    id: Number,
    customer: Object
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  }
}
</script>

<style scoped>

</style>
