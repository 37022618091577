<template>
  <div class="w-full max-w-4xl mx-auto">
    <base-panel title="Login" class="mb-8" no-padding>
      <div
          v-if="form.errors.has('email') || form.errors.has('password')"
          class="
          px-8
          py-2
          border-b border-red-200
          bg-red-100
          text-red-800
          mx-auto
        "
      >
        {{ form.errors.get("email") || form.errors.get("password") }}
      </div>
      <div class="p-6">
        <form @submit.prevent="form.submit()">
          <base-horizontal-field label="Email">
            <base-input v-model="form.email"></base-input>
          </base-horizontal-field>
          <base-horizontal-field label="Password">
            <base-input v-model="form.password" type="password"></base-input>
          </base-horizontal-field>
          <base-horizontal-field>
            <div class="flex justify-end">
              <base-button
                  type="submit"
                  primary
                  class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
              >Login
              </base-button
              >
            </div>
          </base-horizontal-field>
          <base-horizontal-field>
            <div class="flex align-center justify-center">
              <router-link
                  id="forgot-password"
                  class="text-mp-blue hover:mp-hover-blue"
                  :to="{ path: '/reset-password-request' }"
              >Forgot password?
              </router-link
              >
            </div>
          </base-horizontal-field>
        </form>
      </div>
    </base-panel>
  </div>
</template>

<script>
import BaseButton from "../../components/shared/BaseButton";
import {useRoute, useRouter} from "vue-router/dist/vue-router";

export default {
  components: {
    BaseButton,
  },
  data() {
    const initialData = {
      email: "",
      password: "",
    };

    return {
      form: new window.Form(initialData, async (form) => {
        await this.$auth.login(form);
        if (this.$auth.role("visitor")) {
          this.router.push({name: "NewCustomer"});
        } else if (this.$auth.role("microchip_registrar") || this.$auth.role("implanter")) {
          this.router.push({name: "MicrochipRegistration"});
        } else if (this.$auth.role("superadmin")) {
          this.router.push({name: "AdminPanel"});
        } else {
          this.router.push("/");
        }
      }),
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      route,
      router
    }
  },
  async beforeMount() {
    if (this.route.query.token) {
      const data = {
        token: this.route.query.token,
      };

      await this.$auth.tokenLogin(data);

      if (this.$auth.role("visitor")) {
        await this.router.push({name: "NewCustomer"});
      }

      if (this.$auth.role("microchip_registrar" || this.$auth.role("implanter"))) {
        await this.router.push({name: "MicrochipRegistration"});
      }
    }
  },
};
</script>

<style scoped>
#forgot-password:hover {
  font-size: 1.025rem;
  color: #1e3a8a;
}
</style>