import Http from '../services/Http';

export interface TeamSettingEntity {
    settings: {
        microchip_registration_settings?: {
            top_level_registration_fields?: {
                email?: boolean,
                mobile?: boolean
            }
        }
    };
}

export default class TeamSettingService {

    static async getTeamSettings() {
        const response = await Http.get("/settings");

        const setting: TeamSettingEntity = response.data as TeamSettingEntity;

        return setting;
    }
}
