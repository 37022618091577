<template>
    <table class="w-full" >
        <tbody>
        <router-link
                tag="tr"
                v-for="appointment in pet.appointments"
                :key="appointment.id"
                :to="{ name: 'AppointmentSummary', params: { id: appointment.id } }"
                class="hover:bg-gray-100 cursor-pointer"
        >
            <td class="p-4 w-px">
                <calendar-plus-icon :icon="appointment.icon" class="fill-current w-5"></calendar-plus-icon>
            </td>
            <td class="p-4 pl-0 text-lg">
                {{ formatDate(appointment.occurrence || appointment.created_at) }}

            </td>
        </router-link>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            pet: Object,
        },
        methods:{
            formatDate(value){

                var date = new Date(value);

               return date.toLocaleString()



                // return date.toISOString().slice(0, 10);
            }
        }
    }
</script>

<style scoped>

</style>