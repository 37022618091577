<template>
  <div class="mt-4">
  <a href="#" @click="router.go(-1)" class="bg-transparent hover:text-mp-hover-blue text-mp-blue py-2 px-4">
    <ChevronLeftIcon class="h-5 w-5 inline-block mb-0.5 -mr-1" aria-hidden="true" />
    Back
  </a>
  </div>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/24/solid'
import {useRouter} from "vue-router";
export default {
  components:{
    ChevronLeftIcon
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  }
}
</script>

<style scoped>

</style>