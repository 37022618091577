<template>
  <div class="w-full flex justify-center items-center">
    <div class="bg-white border-l-8 border-mp-blue mt-4 rounded-lg w-full shadow-lg">
      <div class="w-full flex">
        <div class="w-1/6 pt-6 flex justify-center">
        </div>
        <div class="w-full pt-9 pr-4">
          <h3 class="font-bold text-mp-blue inline-block">This microchip was found on the <p class="text-mp-green text-bold inline-block">{{ db.name }}</p> database</h3>
          <p class="py-4 text-sm text-gray-400">Please visit their website below for more information</p>
          <a
              :href="'https://' + db.website"
              target="_blank"
              rel="noopener"
              class="v-markdown--link text-mp-blue"
          >
                    <span>{{ db.website_display || db.website }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        </div>
      </div>
      <div class="p-4 flex space-x-4">
        <a href="#" @click="$emit('resetPage')" class="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Reset</a>
        <a href="#" @click="$emit('searchAgain')" class="w-1/2 px-4 py-3 text-center text-white bg-mp-dark-green rounded-lg hover:bg-mp-hover-blue hover:text-white font-bold text-sm">Search again</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    db: Object
  },
}
</script>

<style scoped>

</style>