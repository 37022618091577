import Model from "./Model";
import Pet from "./Pet";

export default class Customer extends Model {
  getRelationships() {
    return {
      pets: Pet,
    };
  }

  get first_five_pets() {
    return this.pets.slice(0, 5);
  }

  get other_pets() {
    return this.pets.slice(5);
  }

  get fullname() {
    return this.first_name + " " + this.last_name;
  }

  static async create(form) {
    const data = await window.Http.post("customers", form);
    return this.make(data);
  }

  static async all() {
    const data = await window.Http.get("customers");
    return this.make(data);
  }

  static async getByQuery(query) {
    let data = "";
    if (query) {
      data = await window.Http.get("customers?query=" + query);
    } else {
      data = await window.Http.get("customers");
    }

    return this.make(data);
  }

  static async getById(id) {
    const data = await window.Http.get("customers/" + id + "?pets");
    return this.make(data);
  }

  async update(form) {
    const data = await window.Http.put("customers/" + this.id, form);
    return this.fill(data);
  }

  static async deleteMany(ids) {
    await window.Http.delete("customers", { ids: ids });
  }

  async delete() {
    const data = await window.Http.delete("customers/" + this.id);
    return this.fill(data);
  }
}
