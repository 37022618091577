<template>
  <div>
    <p v-if="customers.length < 1" class="p-4">No customers found.</p>
    <div v-else id="customers-body" class="bg-mp-off-white" style="overflow: auto;">
      <div>
        <button
            type="button"
            class="float-left min-w-16 mr-2 text-xl font-LexendDeca text-mp-dark-green mb-1 mt-1 ml-1 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            @click="toggle"
        >
          Actions
        </button>
        <div
            v-if="dropdownOpen"
            id="actions-bar"
            class="origin-top-left absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
        >
          <div class="py-1">
            <div
                @click="showConfirmDeleteModal"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
            >
              Delete
            </div>
          </div>
        </div>
      </div>
      <table class="w-full bg-white">
        <thead>
        <tr
            class="text-sm text-xl font-LexendDeca text-mp-blue text-left bg-mp-off-white"
        >
          <th class="font-medium p-4">
            <input
                type="checkbox"
                class="form-checkbox h-5 w-5 text-mp-blue"
                v-model="selectAll"
                @click="select"
            />
            <i class="form-icon"></i>
          </th>
          <th class="font-medium p-4">Name</th>
          <th class="font-medium p-4">Telephone #</th>
          <th class="font-medium p-4">Address</th>
          <th class="font-medium p-4">Pets</th>
        </tr>
        </thead>
        <tbody>
        <tr

            :key="customer.id"
            v-for="customer in customers"
            @click="rowClicked(customer)"
            class="tablelink border-t cursor-pointer"
        >
          <td class="p-4" @click.stop="closeConfirmDeleteModal">
            <input
                @click.stop="closeConfirmDeleteModal"
                type="checkbox"
                :value="customer.id"
                v-model="selected"
                class="form-checkbox h-5 w-5 text-mp-blue mt-2"
            />
          </td>
          <td class="p-4">
            <div class="capitalize font-semibold text-lg">
              {{ customer.first_name || "(unknown)" }}
              {{ customer.last_name || "(unknown)" }}
            </div>
            <div>
              {{ customer.email || "(unknown)" }}
            </div>
          </td>
          <td class="p-4">
            <div>
              {{ customer.home_number || "(unknown)" }}
            </div>
            <div>
              {{ customer.mobile_number || "(unknown)" }}
            </div>
          </td>
          <td class="p-4">
            <div>
              {{ customer.address_line_1 || "(unknown)" }}
              {{ customer.address_line_2 }}
            </div>
            <div>
              {{ customer.town || "(unknown)" }}
              {{ customer.postcode || "(unknown)" }}
            </div>
          </td>
          <td class="p-4">
            <div
                :key="pet.id"
                v-for="(pet, index) in customer.first_five_pets"
                class="inline"
            >
              <router-link
                  id="petlink"
                  :to="{ name: 'PetSummary', params: { id: pet.id } }"
                  class="over:text-black mr-6"
                  v-text="pet.name"
              ></router-link>
            </div>
            <div v-if="customer.other_pets.length > 0" class="inline">
              and {{ customer.other_pets.length }} more
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <confirm-delete
        v-show="isConfirmDeleteModalVisible && this.selected.length > 0"
        :modalHeadline="
        'Delete ' +
        selected.length +
        ' customer' +
        (selected.length == 1 ? '' : 's')
      "
        deleteMessage="Are you sure?"
        @deleteRecordEvent="deleteCustomers"
        @close="closeConfirmDeleteModal"
    ></confirm-delete>
  </div>
</template>

<script>
import Customer from "../../models/Customer";
import ConfirmDelete from "../modals/ConfirmDelete";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  props: {
    customers: Array,
    search: String,
  },
  components: {
    ConfirmDelete,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      customer: "",
      dropdownOpen: false,
      isConfirmDeleteModalVisible: false,
      selected: [],
      selectAll: false,
    };
  },

  created() {
    window.addEventListener("click", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },

  methods: {
    async rowClicked(customer) {
      await this.router.push({
        name: "CustomerSummary",
        params: {id: customer.id}
      });
    },
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },

    toggle() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownOpen = false;
      }
    },
    async deleteCustomers() {
      const result = await Customer.deleteMany(this.selected);
      await this.getCustomers();
    },
    select() {
      this.dropdownOpen = false;
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.filteredCustomers) {
          this.selected.push(this.filteredCustomers[i].id);
        }
      }
    },
    async getCustomers() {
      await this.$emit("getCustomers", this.search);
    },
  },
};
</script>

<style scoped>
.table-text {
  color: #627c9f;
}

.tablelink:hover {
  color: #0074b5;
}

#petlink {
  display: inline-block;
  background: #e0e0e0;
  padding: 0 12px;
  border-radius: 32px;
  font-size: 13px;
}

#petlink:hover {
  background: #ccc;
  font-size: 1.125rem;
}

#customers-body {
  position: relative;
}

#actions-bar {
  position: absolute;
  top: 48px;
  left: 4px;
}
</style>
