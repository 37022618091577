<template>
    <div>
        <base-panel title="Reset account password" class="w-full max-w-4xl mx-auto">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <password-reset-request v-else></password-reset-request>
        </base-panel>
    </div>
</template>

<script>
    import AccountSettings from "../../components/admin/AccountSettings";
    import PasswordResetRequest from "../../components/admin/PasswordResetRequest";

    export default {
        components: {PasswordResetRequest, AccountSettings},
        data() {
            return {
                team: Object,
                loading: false,
            }
        },

    }
</script>

<style scoped>

</style>
