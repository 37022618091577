<template>
    <div id="customer-details-results-table"
         class="w-5/6 rounded overflow-hidden shadow-lg p-2 border-solid border-2 shadow-sm">
        <div class="flex font-LexendDeca">
            <user-icon icon="user" class="fill-current w-20 ml-2 mr-2"></user-icon>
            <div class="customer-properties ml-2">
                <div class="capitalize font-semibold text-lg">
                    {{ customer.first_name }} {{ customer.last_name }}
                </div>
                <div v-if="customer.email">
                    {{ customer.email }}
                </div>
                <div v-if="customer.home_number">
                    {{ customer.home_number }}
                </div>
                <div v-if="customer.mobile_number">
                    {{ customer.mobile_number }}
                </div>
            </div>
        </div>
        <div class="buttons flex py-2">
            <base-button primary class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                         @click="router.push({ name: 'CustomerDetails', params: { id: customer.id } })">
                <span>Edit</span>
            </base-button>
            <base-button primary class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                         @click="router.push({ name: 'NewPet', params: { customer_id: customer.id } })">
                <span>Add pet</span>
            </base-button>
        </div>
        <p
                v-if="customer.pets.length < 1"
                class="px-6 mb-4 mt-4 empty-table"
        >
            No pets found for this customer.
        </p>
        <table class="w-full" v-else>
            <thead>
            <tr>
                <th class="text-lg font-LexendDeca text-mp-blue bg-mp-off-white py-2">Type</th>
                <th class="text-lg font-LexendDeca text-mp-blue bg-mp-off-white py-2">Name</th>
                <th class="text-lg font-LexendDeca text-mp-blue bg-mp-off-white py-2">Breed</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    v-for="pet in customer.pets"
                    :key="pet.id"
                    @click="rowClicked(pet)"
                    class="hover:bg-gray-100 cursor-pointer hover:bg-mp-sky-blue"
            >
                <td class="py-4">
                    <dog-icon v-if="pet.icon === 'dog'" class="fill-current w-8"></dog-icon>
                    <cat-icon v-if="pet.icon === 'cat'" class="fill-current w-8"></cat-icon>
                    <rabbit-icon v-if="pet.icon === 'rabbit'" class="fill-current w-8"></rabbit-icon>
                    <hamster-icon v-if="pet.icon === 'hamster'" class="fill-current w-8"></hamster-icon>
                    <horse-icon v-if="pet.icon === 'horse'" class="fill-current w-8"></horse-icon>
                    <paw-icon v-if="pet.icon === 'paw'" class="fill-current w-8"></paw-icon>
                </td>
                <td class="py-4 text-lg">
                    {{ pet.name }}
                </td>
                <td>
          <span
                  v-if="pet.breed"
                  class="inline-block ml-2 bg-gray-200 rounded border border-gray-400 text-sm px-2"
                  v-text="pet.breed.value"
          ></span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import UserIcon from "../shared/icons/UserIcon"
import DogIcon from "../shared/icons/DogIcon"
import RabbitIcon from "../shared/icons/RabbitIcon"
import HamsterIcon from "../shared/icons/HamsterIcon"
import HorseIcon from "../shared/icons/HamsterIcon"
import PawIcon from "../shared/icons/PawIcon"
import CatIcon from "../shared/icons/CatIcon"
import {useRouter} from "vue-router/dist/vue-router";

export default {
    components: {UserIcon, DogIcon, CatIcon, RabbitIcon, HamsterIcon, HorseIcon, PawIcon},
    props: {
        customer: Object
    },
    setup() {
        const router = useRouter();

        return {
            router
        }
    },
    methods: {
        async rowClicked(pet) {
            await this.router.push({
                name: "PetSummary",
                params: {id: pet.id},
            });
        }
    },
    data() {
        return {
            form: new window.Form(this.customer.getAttributes(), async form => {
                this.customer.update(form)
            })
        }
    },
}
</script>

<style scoped>
</style>
