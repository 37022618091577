export default class FormService {
    static setCursorPosition(element: HTMLInputElement, position: number) {
        element.focus();
        element.setSelectionRange(position, position);
    }

    static handleKeyboardEvent(event: KeyboardEvent, keys: Array<string>, callback: () => void) {
        if (keys.includes(event.key)) {
            callback();
        }
    }
}