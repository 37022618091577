<template>
    <div class="flex justify-end items-center relative">
        <input
                autocomplete="off"
                :type="type"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                class="h-12 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
                :class="[inputError, inputClass, invalidError, toUppercase]"
        />
        <div class="absolute mr-2 w-10">
            <slot></slot>
        </div>
        <div v-if="error" class="mt-1 text-red-700" v-text="error"></div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: null,
        placeholder: String,
        inputClass: String,
        error: String,
        isInvalid: {
            type: Boolean,
            default: false,
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    emits: ['update:modelValue'],
    computed: {
        inputError() {
            return this.error ? 'border-red-500' : 'border-gray-200'
        },
        invalidError() {
            return this.isInvalid ? 'border-red-500' : 'border-gray-200'
        },
        toUppercase() {
            return this.uppercase ? 'uppercase' : ''
        }
    },
}
</script>
