<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto px-24">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div
            class="c-card inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
          <h3 class="c-card-header">{{ modalHeadline }}</h3>
          <div class="c-card-body">
            <div class="quote-img"></div>
            <div class="description">
              <div class="c-row mb-4" style="width: -webkit-fill-available">
                <div class="key text-right">Current cost price:</div>
                <div class="value text-left">{{ currentCost }}</div>
                <div class="key text-right">Current retail price:</div>
                <div class="value text-left">{{ currentRetail }}</div>
              </div>
              <div class="c-row mt-4" style="width: -webkit-fill-available">
                <div class="key text-right">New cost price:</div>
                <base-input
                    class="input value text-left"
                    v-model="cost_price"
                ></base-input>
                <div class="key text-right">New retail price:</div>
                <base-input
                    class="input value text-left"
                    v-model="retail_price"
                ></base-input>
              </div>
            </div>
          </div>
          <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-between sm:flex-row-reverse"
          >
            <base-button
                type="button"
                @click="confirmPrice"
                primary
                class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
            >
              Update
            </base-button>
            <base-button type="button" @click="close"> Back</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "modal",
  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    currentCost: {
      type: String,
      required: true,
    },
    currentRetail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cost_price: "",
      retail_price: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmPrice() {
      this.$emit("confirmPriceEvent", [
        {cost_price: this.cost_price, retail_price: this.retail_price},
      ]);

      this.cost_price = "";
      this.retail_price = "";
    },
  },
};
</script>
<style>
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.c-card {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  width: 800px;
  font-family: "Lexend Deca";
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 7px;
  box-shadow: 3px 5px 7px 5px #888888;
}

.c-card .c-card-header {
  border-radius: 7px 7px 0px 0px;
  background-color: #0074b5;
  color: white;
  margin-bottom: 0.75rem;
  padding: 0.5rem !important;
  text-align: center !important;
  font-size: 1.75rem;
  margin-top: 0px;
}

.c-card .c-card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  display: flex;
}

.c-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.key {
  width: 50%;
  color: #1553cb;
  padding-right: 10px;
  font-weight: 500;
}

.value {
  width: 50%;
  padding-left: 10px;
}

.description {
  padding-top: inherit;
}
</style>