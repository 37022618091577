<template>
  <img :src="src" alt="add-customer" />
</template>

<script>

import src from "../../../../resources/icons/add-customer.svg";

export default {
  setup() {
    return {
      src
    };
  }
}
</script>
