<template>
  <select
      @input="$emit('input', $event.target.value)"
      class="h-12 bg-gray-100 border w-18 rounded text-gray-700 focus:border-purple-500 border-gray-200">
    <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        v-text="option.display"
        :selected="option.value === value"
    ></option>
  </select>

</template>

<script>
export default {
  props: {
    value: null,
  },
  data() {
    return {
      options: [
        {
          value: "GB",
          display: "🇬🇧"
        },
        {
          value: "IE",
          display: "🇮🇪"
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>