<template>
  <div class="w-full max-w-4xl mx-auto mt-8">
    <div
      v-if="loading"
      class="flex h-16 items-center justify-center font-semibold text-gray-600"
    >
      Loading...
    </div>
    <div v-else class="flex items-start">
      <pet-summary-table
        :appointments="appointments"
        :pet="pet"
      ></pet-summary-table>
      <!--            <pet-appointment-summary-table class="ml-10" :pet="pet" ></pet-appointment-summary-table>-->
    </div>
  </div>
</template>

<script>
import PetSummaryTable from "../../components/admin/PetSummaryTable";
import Pet from "../../models/Pet";
import * as Swal from "sweetalert2";
import PetAppointmentSummaryTable from "../../components/admin/PetAppointmentSummaryTable";
import Appointment from "../../models/Appointment";

export default {
  props: {
    id: Number,
  },
  components: {
    PetAppointmentSummaryTable,
    PetSummaryTable,
  },
  data() {
    return {
      appointments: Array,
      pet: {},
      vaccines: [],
      loading: false,
      vaccines_loading: false,
    };
  },
  methods: {
    async getAppointments() {
      this.loading = true;
      try {
        this.appointments = await Appointment.findbyPet(this.id);
      } finally {
      }
    },
    async getPet() {
      this.loading = true;
      try {
        this.pet = await Pet.findWithFlag(
          this.id,
          "appointments&vaccines&waitlists&customers&petBreed"
        );
      } finally {
      }
    },
    async delete_pet() {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This will delete all associated pet data including pet events.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete this pet?",
      });

      if (!result.value) {
        return;
      }

      await Pet.delete(this.pet);
      this.router.push({ name: "Pets" });
    },
  },
  mounted() {},
  async beforeMount() {
    await this.getPet();
    await this.getAppointments();
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
