<template>
    <div id="medication-summary-table">
        <div class="max-w-lg rounded overflow-hidden shadow-lg">
            <user-icon class="fill-current w-6"></user-icon>
            <div class="px-6 py-4">
                <div class="capitalize font-semibold text-lg">
                    {{ medication.name || 'unknown' }}
                </div>
                <base-horizontal-field
                        tag="tr"
                        v-for="batch in medication.batches"
                        :key="batch.id"
                >
                    <div class="p-4 w-px">
                        <user-icon class="fill-current w-5"></user-icon>
                    </div>
                    <base-horizontal-field label="Batch id">
                        <div class="ml-4"> {{ batch.value || 'unknown' }} </div>
                    </base-horizontal-field>
                    <base-horizontal-field label="Created">
                        <div class="ml-4"> {{ format(batch.created_at) || 'unknown' }} </div>
                    </base-horizontal-field>
                </base-horizontal-field>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseHorizontalField from "../shared/BaseHorizontalField";
    export default {
        components: {BaseHorizontalField},
        props: {
            medication: Object,
        },
        methods:{
            format(date){
                var d;

                if(date instanceof Date) { d = date } else { d = new Date(date) }

                return d.toLocaleDateString('en-GB', {
                    day : 'numeric',
                    month : 'numeric',
                    year : 'numeric'
                })
            },
        }

    }
</script>

<style scoped>

</style>
