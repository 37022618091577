import Model from "./Model";

export default class Batch extends Model {
  getRelationships() {
    return {
      //
    };
  }

  get icon() {
    return "user";
  }

  static async create(batch) {
    const data = await window.Http.post("batches", batch);
    return data;
  }
}
