<template>
  <div>
    <base-panel title="Pets" no-padding>
      <template #search>
        <input
            class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal mr-5"
            v-model="search" type="text" placeholder="Search by name, email..">
      </template>
      <template #actions>
        <base-button primary @click="router.push({ name: 'NewPet' })"
                     class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">
          Create new pet
        </base-button>
      </template>
      <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
      <pets-results-table :pets="pets" v-else></pets-results-table>
    </base-panel>
  </div>
</template>

<script>
// import axios from "axios";
import PetsResultsTable from '../../components/admin/PetsResultsTable.vue'
import Pet from '../../models/Pet'
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: 'app',
  components: {
    PetsResultsTable
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      pets: [],
      loading: false,
      search: ''
    }
  },
  methods: {
    async listAllPets() {
      this.loading = true
      try {
        // const result = await axios({ method: "GET", "url": this.$api_host + "/Pet" })
        // this.pets = result.data.response.pets;

        this.pets = await Pet.all();
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
  },
  beforeMount() {
    this.listAllPets()
  },
}
</script>
