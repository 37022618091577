<template>
  <div id="app" class="bkg text min-h-screen">
    <nav class="nav_bar bg-mp-blue text-mp-off-white shadow px-6 py-2">
      <div class="nav_header" v-if="$auth.check()">
        <div id="nav_logo">
          <img
              @click="router.push({ name: 'Customers' })"
              src="../src/assets/logo.svg"
              class="homelogo w-24 mr-4"
          />
        </div>

        <a href="javascript:void(0);" style="font-size: 15px" class="icon" onclick="myFunction()">&#9776;</a>

        <div class="topnav flex items-center" id="myTopnav" v-if="$auth.check()">
          <div class="menu_list">
            <div @click="showNavBar = false">
              <router-link
                  v-if="$auth.role('admin')"
                  class="
                  navlink
                  ml-6
                  mr-4
                  inline-flex
                  items-center
                  font-LexendDeca
                  text-base
                  hover:underline
                "
                  :to="{ path: '/customers' }"
              >
                <address-book-icon
                    icon="address-book"
                    class="fill-current w-4 h-4 mr-3 mb-2"
                ></address-book-icon>
                <span>Customers</span>
              </router-link>
            </div>
            <div @click="showNavBar = false">
              <router-link
                  v-if="$auth.role('admin')"
                  class="
                  navlink
                  ml-6
                  mr-4
                  inline-flex
                  items-center
                  font-LexendDeca
                  text-base
                  hover:underline
                "
                  :to="{ path: '/waitlist' }"
              >
                <list-ul-icon
                    icon="list-ul"
                    class="fill-green-500 w-4 h-4 mr-3 mb-2"
                ></list-ul-icon>
                <span>Waitlist</span>
              </router-link>
            </div>
            <div @mouseenter="setMenuItemCategory('microchip')" @click="showNavBar = true">
              <router-link
                  v-if="$auth.role('admin') || $auth.role('microchip_registrar') || $auth.role('implanter')"
                  class="
                  navlink
                  ml-6
                  mr-4
                  inline-flex
                  items-center
                  font-LexendDeca
                  text-base
                  hover:underline
                "
                  :to="{ path: '/microchip-registration' }"
              >
                <microchip-icon
                    icon="microchip"
                    class="fill-current w-4 h-4 mr-2"
                ></microchip-icon>
                <span>Microchip</span>
              </router-link>
            </div>
            <div @click="showNavBar = false">
              <router-link
                  v-if="$auth.role('admin') || $auth.role('customer')"
                  class="
                  navlink
                  ml-6
                  mr-4
                  inline-flex
                  items-center
                  font-LexendDeca
                  text-base
                  hover:underline
                "
                  @mouseover="setMenuItemCategory('microchip')"
                  :to="{ path: '/settings' }"
              >
                <cog-icon class="text-white w-4 h-4 mr-3 mb-2"></cog-icon>
                <span>Settings</span>
              </router-link>
            </div>
            <div @click="showNavBar = false">
              <router-link
                  v-if="$auth.role('superadmin')"
                  class="
                  navlink
                  ml-6
                  mr-4
                  inline-flex
                  items-center
                  font-LexendDeca
                  text-base
                  hover:underline
                "
                  :to="{ path: '/admin-panel' }"
              >
                <list-ul-icon class="fill-current w-4 h-4 mr-2"></list-ul-icon>
                <span>Admin</span>
              </router-link>
            </div>
          </div>
          <div class="nav_logout">
            <div class="font-semibold font-LexendDeca" v-text="$auth.user().name"></div>
            .<a href="#" class="navlink font-LexendDeca" @click.prevent="logout">Logout</a>
          </div>
        </div>

      </div>

      <div class="header_menu_wrp flex items-center" v-else>
        <img
            @click="router.push({ name: 'Login' })"
            src="../src/assets/logo.svg"
            class="homelogo w-24"
        />
        <router-link class="font-LexendDeca nav_login" :to="{ name: 'Login' }">
          Login
        </router-link>
      </div>
    </nav>

    <horizontal-nav-bar
        v-if="navItems.length > 0 && showNavBar == true"
        :items="navItems"
        @setMenuItemCategory="setMenuItemCategory"
    ></horizontal-nav-bar>

    <back-button
        v-if="!route.meta.hideBackButton && route.path != '/'"
    ></back-button>
    <main class="container mt-8">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import BackButton from "@/components/shared/buttons/BackButton";
import {useRoute, useRouter} from 'vue-router'

export default {
  name: "app",
  components: {BackButton},
  data() {
    return {menuItemsCateory: "", showNavBar: false};
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      route,
      router
    }
  },
  beforeMount() {
    this.$auth.refresh();
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      router.push({name: "Login"});
    },
    async setMenuItemCategory(category) {
      this.menuItemsCateory = category;
    },
  },
  watch: {
    items: {
      handler(val, oldVal) {
        console.log(oldVal + ' --> ' + val)
      },
      deep: true
    }
  },
  computed: {
    navItems() {
      if (this.menuItemsCateory === "microchip") {
        let items = [
          {
            name: "Register Microchips",
            routeName: "MicrochipRegistration",
          },
          {
            name: "Registration History",
            routeName: "MicrochipRegistrationHistory",
          },
        ];

        if (!this.$auth.role("implanter")) {
          items.splice(1, 0, {
            name: "Microchip Search",
            routeName: "MicrochipSearch",
          });
        }

        return items.map((item, index) => ({
          id: index + 1,
          name: item.name,
          routeName: item.routeName,
        }));
      }

      return [];
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
/*@import url("https://fonts.googleapis.com/cssfamily=Lexend+Deca&display=swap");*/
@tailwind base;
@tailwind components;
@tailwind utilities;
.bkg {
  background-color: #f9f9f9;
}

.text {
  color: #627c9f;
}

/* .link {
  @apply .px-4 .py-2 .rounded-lg .border .border-transparent;
} */

.homelogo {
  cursor: pointer;
}

/* .link:hover {
  @apply .bg-gray-100 .border-gray-500;
} */

.navlink:hover {
  font-size: 1.125rem;
}

.ellipsis {
  text-overflow: ellipsis;
}

.container {
  padding-bottom: 70px;
}

.swal2-text {
  font-family: "Lexend Deca";
}

.swal2-content {
  font-family: "Lexend Deca";
}

.swal2-actions {
  font-family: "Lexend Deca";
}

.table.b-table > thead > tr > th {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}

.back-btn:hover {
  font-size: 1.05rem;
}

/* Menu Important styles */
#toggle {
  display: block;
  width: 28px;
  height: 30px;
  margin: 30px auto 10px;
}

#toggle span:after,
#toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
}

#toggle span:after {
  top: 9px;
}

#toggle span {
  position: relative;
  display: block;
}

#toggle span,
#toggle span:after,
#toggle span:before {
  width: 100%;
  height: 5px;
  background-color: #888;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

/* on activation */
#toggle.on span {
  background-color: transparent;
}

#toggle.on span:before {
  transform: rotate(45deg) translate(5px, 5px);
}

#toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -8px);
}

#toggle.on + #menu {
  opacity: 1;
  visibility: visible;
}

/* menu appearance*/
.topnav {
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #04aa6d;
  color: white;
}

.topnav .icon {
  display: none;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

a.icon {
  display: none;
}

.nav_bar {
  display: flex;
  align-items: center;
}

a.navlink {
  display: inline-flex;
}

.nav_logout {
  display: inline-flex;
  align-items: center;
}

.header_menu_wrp {
  justify-content: space-between;
  width: 100%;
}

.menu_list {
  display: flex;
}

.nav_header {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 900px) {
  div#myTopnav {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  a.icon {
    position: absolute;
    top: 12px;
    right: 15px;
  }

  a.icon {
    display: block;
  }

  .topnav.responsive {
    position: relative;
    display: block !important;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: flex !important;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }

  .nav_bar {
    display: inherit !important;
  }

  .nav_logout {
    display: flex;
  }

  .nav_header {
    display: inherit;
  }

  .menu_list {
    display: inline-block;
  }
}
</style>