<template>
  <div class="bg-orange-100 border-l-8 rounded-lg  border-orange-500 text-orange-700 p-4 shadow-md" role="alert">
    <p class="font-bold">{{warning}}</p>
    <p class="text-sm">{{message}}.</p>
  </div>
</template>

<script>
export default {
  props: {
    message: "",
    warning: "",
  }
}
</script>

<style scoped>

</style>