

import {defineComponent, PropType} from "vue";

interface OptionProps {
  text: string,
  value: string
}

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<OptionProps["value"]>,
      default: null,
    },
    options: {
      type: Array as PropType<OptionProps[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: {} as OptionProps | null
    }
  },
  mounted() {
    this.selectedOption = this.options?.find((option) => option?.value === this.modelValue) || null;
    this.$emit('update:modelValue', this.selectedOption?.value);

  },
  methods: {
    onOptionSelected(option: OptionProps) {
      this.selectedOption = option;

      this.$emit('update:modelValue', option.value);
    }
  }
});
