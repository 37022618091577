<template>
  <div class="w-full max-w-4xl mx-auto">
    <base-panel title="Pet Details" class="mb-8">
      <div
          v-if="loading"
          class="flex h-16 items-center justify-center font-semibold text-gray-600"
      >
        Loading...
      </div>
      <pet-edit-table :pet="pet" v-else></pet-edit-table>
    </base-panel>
  </div>
</template>

<script>
import Pet from "../../models/Pet";
import PetEditTable from "../../components/admin/PetUpdateForm";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  props: {
    id: Number,
  },
  components: {
    PetEditTable,
  },
  data() {
    return {
      pet: {},
      vaccines: [],
      loading: false,
      vaccines_loading: false,
    };
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async getPet() {
      this.loading = true;
      try {
        this.pet = await Pet.find(this.id);
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
  },
  beforeMount() {
    this.getPet();
  },
};
</script>

<style scoped>
</style>
