<template>
    <div class="w-full max-w-4xl mx-auto">
        <base-panel title="Customer Details" class="mb-8">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <customer-details-results-table :customer="customer" v-else></customer-details-results-table>
        </base-panel>
    </div>
</template>

<script>
    import CustomerDetailsResultsTable from "../../components/admin/CustomerDetailsResultsTable";
    import Customer from "../../models/Customer";
    import Pet from "../../models/Pet";

    export default {
        props: {
          id: Number
        },
        components: {
            CustomerDetailsResultsTable
        },
        data() {
            return {
                customer: '',
                pets: Array,
                loading: false,
                pets_loading: false
            }
        },
        methods: {
            async getCustomer() {
                this.loading = true
                try {
                    this.customer = await Customer.getById(this.id, true);
                } catch(error){
                    await this.$auth.logout();
                    this.router.push({ name: "Login" });
                } finally {
                    this.loading = false
                }
            },
            async getPetsByCustomer() {
                this.pets_loading = true
                try {
                    this.pets = await Pet.getByCustomerId(this.id);
                } finally {
                    this.pets_loading = false
                }
            }
        },
        mounted() {
        },
        beforeMount(){
            this.getCustomer();
            // this.getPetsByCustomer();
        },
    }
</script>

<style scoped>

</style>
