<template>
  <div>
    <base-horizontal-field label="Name">
      <base-input v-model="form.name"></base-input>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button primary @click="form.submit()"
                     class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">Create
        </base-button>
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import Medication from "../../models/Medication";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {},
  props: {
    pet_id: Number
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      name: ''
    }
    return {
      pet: Object,
      form: new window.Form(initialData, async form => {
        await Medication.create(form);
        this.router.push({name: 'Medications'})
      }),
    }
  },
}
</script>

<style scoped>

</style>
