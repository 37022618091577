<template>
  <div>
    <base-horizontal-field label="Company name" class="mt-2">
      <base-input v-model="form.name"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Company title" class="mt-2">
      <base-input v-model="form.title"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Practitioner description" class="mt-2">
      <base-input v-model="form.practitioner_description"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="VAT number" class="mt-2">
      <base-input v-model="form.vat_no"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Phone number">
      <base-input v-model="form.home_number"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Address line 1">
      <base-input v-model="form.address_line_1"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Address line 2">
      <base-input v-model="form.address_line_2"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Postcode">
      <base-input v-model="form.postcode"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Town">
      <base-input v-model="form.town"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="County">
      <base-input v-model="form.county"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Country">
      <base-input v-model="form.country"></base-input>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button primary @click="form.submit()" class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue">Update
        </base-button>
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import BaseHorizontalField from '../shared/BaseHorizontalField';
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {BaseHorizontalField},
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      form: new window.Form(this.team.getAttributes(), async form => {
        await this.team.update(form);
        await this.$router.push({name: 'Settings'});
      })
    }
  },
  props: {
    id: Number,
    team: Object,
  },
}
</script>

<style scoped>

</style>
