<template>
  <div>
    <base-horizontal-field label="Email" class="mt-2">
      <base-input v-model="form.email"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="New password" class="mt-2">
      <base-input v-model="form.password" type="password"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Confirm password" class="mt-2">
      <base-input
          :error="form.errors.get('password')"
          v-model="form.password_confirmation"
          type="password"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button
            primary
            @click="form.submit()"
            class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
        >Update
        </base-button
        >
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import BaseHorizontalField from "../shared/BaseHorizontalField";
import User from "../../models/User";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  async beforeMount() {
    await this.defaults();
  },
  components: {BaseHorizontalField},
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      email: "",
      token: "",
      password: "",
      password_confirmation: "",
    };

    return {
      user: Object,
      form: new window.Form(initialData, async (form) => {
        await User.updatePasswordViaToken(form);
        await this.$auth.refresh();

        if (this.$auth.role("visitor")) {
          this.router.push({name: "NewCustomer"});
        } else if (this.$auth.role("microchip_registrar") || this.$auth.role("implanter")) {
          this.router.push({name: "MicrochipRegistration"});
        } else if (this.$auth.role("superadmin")) {
          this.router.push({name: "AdminPanel"});
        } else {
          this.router.push("/");
        }
      }),
    };
  },
  methods: {
    async defaults() {
      this.form.email = this.$route.query.email;
      this.form.token = this.$route.query.token;
    },
  },
  props: {
    id: Number,
  },
};
</script>

<style scoped>
</style>