<template>
  <div class="flex flex-col sm:flex-row sm:items-center mb-6 last:mb-0">
    <div
      v-text="label"
      class="sm:w-1/6 sm:text-right sm:mr-8 font-semibold mb-2 sm:mb-0"
    ></div>
    <div class="sm:w-5/6 sm:mr-6 md:mr-20">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
};
</script>
