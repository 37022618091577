<template>
  <div>
    <base-horizontal-field label="Name" class="mt-2">
      <base-input v-model="form.name"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Pet type">
      <base-select
          v-model="petTypeId"
          :options="petTypeOptionsArray"
          label="select animal type"
      ></base-select>
    </base-horizontal-field>

    <base-horizontal-field label="Age">
      <base-input v-model="age"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Date of Birth">
      <vc-date-picker v-model="dobValue" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Date of Adoption">
      <vc-date-picker v-model="form.adoption_date" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Sex">
      <base-toggle
          v-model="form.sex"
          :options="[
          { text: 'Male', value: 'male' },
          { text: 'Female', value: 'female' },
          { text: 'Unknown', value: null },
        ]"
      ></base-toggle>
    </base-horizontal-field>

    <base-horizontal-field label="Breed">
      <multiselect
          v-model="breed"
          :options="petBreedOptions"
          :disabled="!petTypeId"
          :placeholder="(petTypeId) ? 'Select breed' : 'Select pet type first!'"
          label="name"
          track-by="name"
          @tag="addTag"
          :taggable="true"
          :clear-on-select="false"
          :preserveSearch="true"
      ></multiselect>
    </base-horizontal-field>

    <base-horizontal-field label="Main colour">
      <base-input v-model="form.main_colour"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Spayed/Neutered">
      <base-toggle
          v-model="form.spayed"
          :options="[
          { text: 'Yes', value: true },
          { text: 'No', value: false },
          { text: 'Unknown', value: null },
        ]"
      ></base-toggle>
    </base-horizontal-field>

    <base-horizontal-field label="Microchip">
      <base-input
          v-model="form.microchip"
          :error="form.errors.get('microchip')"
      ></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Distinguishing features">
      <base-input v-model="form.distinguishing_features"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="First vaccinations">
      <vc-date-picker v-model="form.first_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Second vaccinations">
      <vc-date-picker v-model="form.second_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Latest vaccinations">
      <vc-date-picker v-model="form.latest_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Kennel cough">
      <vc-date-picker v-model="form.kennel_cough" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Rabies">
      <vc-date-picker v-model="form.rabies" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button
            primary
            @click="form.submit()"
            class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
        >Create
        </base-button
        >
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import 'v-calendar/dist/style.css';
import Pet from "@/models/Pet";
import Multiselect from "vue-multiselect";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {
    Multiselect,
  },
  props: {
    customer_id: Number,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    const initialData = {
      name: "",
      pet_type_id: null,
      sex: null,
      pet_breed_id: null,
      main_colour: "",
      spayed: null,
      microchip: "",
      distinguishing_features: "",
    };

    return {
      age: null,
      dob: null,
      breed: "",
      petBreeds: [],
      petTypes: [],
      petTypeOptionsArray: [],
      petTypeId: null,
      form: new window.Form(initialData, async (form) => {
        if (this.breed.value) {
          if (this.breed.value === 7000) {
            form.new_breed = this.breed.name;
          } else {
            form.pet_breed_id = this.breed.value;
          }
        }
        form.customer_id = this.customer_id;
        form.pet_type_id = this.petTypeId;

        if (this.dob) {
          var date = new Date(this.dob);

          this.form.dob =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              date.getDate().toString().padStart(2, "0");
        }

        // this.form.dob = this.formatDatetimeAsDate(this.dob);
        this.form.adoption_date = this.formatDatetimeAsDate(
            this.form.adoption_date
        );
        this.form.first_vaccinations = this.formatDatetimeAsDate(
            this.form.first_vaccinations
        );
        this.form.second_vaccinations = this.formatDatetimeAsDate(
            this.form.second_vaccinations
        );
        this.form.latest_vaccinations = this.formatDatetimeAsDate(
            this.form.latest_vaccinations
        );
        this.form.kennel_cough = this.formatDatetimeAsDate(
            this.form.kennel_cough
        );
        this.form.rabies = this.formatDatetimeAsDate(this.form.rabies);

        const pet = await Pet.create(form);

        if (this.$auth.role("admin")) {
          await this.router.push({
            name: "PetSummary",
            params: {id: pet.id},
          });
        }

        if (this.$auth.role("customer")) {
          await this.router.push({
            name: "NewCustomer",
          });
        }

        if (this.$auth.role("visitor")) {
          await this.router.push({
            name: "VisitorRegistrationComplete",
          });
        }
      }),
    };
  },
  async beforeMount() {
    this.petTypes = await Pet.getPetTypes();
    this.petTypeOptionsArray = await this.petTypeOptions();
  },
  watch: {
    petTypeId() {
      this.getPetBreeds();
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        value: newTag,
        id: 7000,
      };
      this.petBreeds.push(tag);
      this.breed = {value: 7000, name: newTag};
    },
    petTypeOptions() {
      const parsedPetTypes = this.petTypes.map((petType) => ({
        value: petType.id,
        name: petType.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetTypes];
    },
    deltaDate(input, days, months, years) {
      return new Date(
          input.getFullYear() + years,
          input.getMonth() + months,
          Math.min(
              input.getDate() + days,
              new Date(
                  input.getFullYear() + years,
                  input.getMonth() + months + 1,
                  0
              ).getDate()
          )
      );
    },
    async getPetBreeds() {
      let url = "pet_breeds";

      if (this.petTypeId > 0) {
        url += "?pet_type_id=" + this.petTypeId;
      }
      this.petBreeds = await window.Http.get(url);
    },
  },
  computed: {
    dobValue: {
      get() {
        if (!this.age) return this.dob;

        var birthday = this.deltaDate(new Date(), 0, 0, 0 - this.age);
        this.dob = birthday;

        return birthday;
      },
      set(value) {
        this.dob = value;

        //calculate age
        var date = new Date(value);
        var diff_ms = Date.now() - date.getTime();
        var age_dt = new Date(diff_ms);

        this.age = Math.abs(age_dt.getUTCFullYear() - 1970);
      },
    },
    petBreedOptions() {
      const parsedPetBreeds = this.petBreeds.map((petBreed) => ({
        value: petBreed.id,
        name: petBreed.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetBreeds];
    },
  },
};
</script>

<style scoped>
</style>
