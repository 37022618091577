<template>
  <div>
    <base-panel title="Settings" no-padding>
      <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
      <settings-results-table></settings-results-table>
    </base-panel>
  </div>
</template>

<script>
import SettingsResultsTable from '../../components/admin/SettingsResultsTable.vue'

export default {
  name: 'app',
  components: {
    SettingsResultsTable
  },
  data() {
    return {
      pets: [],
      loading: false,
    }
  },
  methods: {
  },
  mounted() {
  },
  beforeMount(){
  },
}
</script>
