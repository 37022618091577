import Model from "./Model";
import Medication from "./Medication";
import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
};

export default class Appointment extends Model {
  getRelationships() {
    return {
      medications: Medication,
    };
  }

  get icon() {
    return "calendar-plus";
  }

  static async find(id) {
    const data = await window.Http.get(
      "/appointments/" + id + "?batches&animalMonitoringImages"
    );
    return this.make(data);
  }

  static async findWithFlag(id, flag) {
    const data = await window.Http.get("/appointments/" + id + "?" + flag);
    return this.make(data);
  }

  static async findbyPet(id) {
    const data = await window.Http.get(
      "appointments?pet_id=" + id + "&hasAnimalMonitoringImages"
    );
    return this.make(data);
  }

  static async create(form) {
    const data = await window.Http.post("appointments", form);
    return this.make(data);
  }

  async getImage(image) {
    const data = await window.Http.get(
      "appointments/" + this.id + "/images/" + image.id
    );
    return data;
  }

  static async removeImage(imageId) {
    await window.Http.delete("appointments/deleteFile/" + imageId);
  }

  static async uploadBase64Image(data) {
    const response = await window.Http.post(
      "appointments/storeBase64File",
      data
    );

    return response.file;
  }

  static async uploadImage(data) {
    const headers = { "Content-Type": "multipart/form-data" };

    const response = await window.Http.post(
      "appointments/storeFile",
      data,
      headers
    );

    return response.file;
  }

  async update(form) {
    const data = await window.Http.put("appointments/" + this.id, form);
    return this.fill(data);
  }
}
