<template>
  <img :src="src" alt="rabbit" />
</template>

<script>

import src from "../../../../resources/icons/rabbit.svg";

export default {
  setup() {
    return {
      src
    };
  }
}
</script>
