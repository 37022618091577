<template>
  <div id="pet-details-results-table">
    <base-horizontal-field label="Name" class="mt-2">
      <base-input input-class="capitalize" v-model="form.name"></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Pet type">
      <base-select
          v-model="petTypeId"
          :options="petTypeOptionsArray"
          label="select animal type"
      ></base-select>
    </base-horizontal-field>
    <base-horizontal-field label="Age">
      <base-input v-model="ageValue"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Date of Birth">
      <vc-date-picker v-model="dobValue" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Date of Adoption">
      <vc-date-picker v-model="form.adoption_date" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

        <base-horizontal-field label="Sex">
          <base-toggle
              v-model="form.sex"
              :options="[
              { text: 'Male', value: 'male' },
              { text: 'Female', value: 'female' },
              { text: 'Unknown', value: null },
            ]"
          ></base-toggle>
        </base-horizontal-field>

    <base-horizontal-field label="Breed">
      <multiselect
          v-model="breed"
          :disabled="!petTypeId"
          :options="petBreedOptions"
          :placeholder="(petTypeId) ? 'Select breed' : 'Select pet type first!'"
          :custom-label="name"
          label="name"
          track-by="name"
          @tag="addTag"
          :taggable="true"
          :clear-on-select="false"
          :preserveSearch="true"
      ></multiselect>
    </base-horizontal-field>

    <base-horizontal-field label="Main colour">
      <base-input v-model="form.main_colour"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="Spayed/Neutered">
      <base-toggle
          v-model="form.spayed"
          :options="[
          { text: 'Yes', value: true },
          { text: 'No', value: false },
          { text: 'Unknown', value: null },
        ]"
      ></base-toggle>
    </base-horizontal-field>

    <base-horizontal-field label="Microchip">
      <base-input
          v-model="form.microchip"
          :error="form.errors.get('microchip')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Distinguishing features">
      <base-input v-model="form.distinguishing_features"></base-input>
    </base-horizontal-field>

    <base-horizontal-field label="First vaccinations">
      <vc-date-picker v-model="form.first_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Second vaccinations">
      <vc-date-picker v-model="form.second_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Latest vaccinations">
      <vc-date-picker v-model="form.latest_vaccinations" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Kennel cough">
      <vc-date-picker v-model="form.kennel_cough" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field label="Rabies">
      <vc-date-picker v-model="form.rabies" mode="date">
        <template v-slot="{ inputValue, inputEvents, hidePopover }">
          <input
              class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
              :value="inputValue"
              v-on="inputEvents"
              @keyup.enter.tab="hidePopover"
          />
        </template>
      </vc-date-picker>
    </base-horizontal-field>

    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button
            primary
            @click="form.submit()"
            class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
        >Update
        </base-button
        >
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import 'v-calendar/dist/style.css';
import Multiselect from "vue-multiselect";
import BaseInput from "../shared/BaseInput.vue";
// import vDatePicker from "v-calendar/lib/components/date-picker.umd";
import BaseHorizontalField from "../shared/BaseHorizontalField.vue";
import {useRouter} from "vue-router/dist/vue-router";
import Pet from "@/models/Pet";

export default {
  components: {
    Multiselect,
    BaseInput,
    BaseHorizontalField,
  },
  props: {
    pet: Object,
    vaccinations: Array,
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      age: null,
      dob: null,
      breed: "",
      petTypeId: null,
      date: new Date(),
      inputValue: "",
      loadedDob: false,
      petTypes: [],
      petTypeOptionsArray: [],
      petBreeds: [],
      form: new window.Form(this.pet.getAttributes(), async (form) => {
        if (this.breed.value) {
          if (this.breed.value === 7000) {
            form.new_breed = this.breed.name;
          } else {
            form.pet_breed_id = this.breed.value;
          }
        }

        if (this.form.adoption_date) {
          this.form.adoption_date = this.formatDatetimeAsDate(
              this.form.adoption_date
          );
        }

        if (this.form.first_vaccinations) {
          this.form.first_vaccinations = this.formatDatetimeAsDate(
              this.form.first_vaccinations
          );
        }

        if (this.form.second_vaccinations) {
          this.form.second_vaccinations = this.formatDatetimeAsDate(
              this.form.second_vaccinations
          );
        }

        if (this.form.latest_vaccinations) {
          this.form.latest_vaccinations = this.formatDatetimeAsDate(
              this.form.latest_vaccinations
          );
        }

        if (this.form.kennel_cough) {
          this.form.kennel_cough = this.formatDatetimeAsDate(
              this.form.kennel_cough
          );
        }

        if (this.form.rabies) {
          this.form.rabies = this.formatDatetimeAsDate(this.form.rabies);
        }

        this.form.pet_type_id = this.petTypeId;

        if (this.dob) {
          var date = new Date(this.dob);

          this.form.dob =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1).toString().padStart(2, "0") +
              "-" +
              date.getDate().toString().padStart(2, "0");
        }

        await this.pet.update(form);
        await this.router.push({
          name: "PetSummary",
          params: {id: this.pet.id},
        });
      }),
    };
  },
  watch: {
    petTypeId() {
      this.getPetBreeds();
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        value: newTag,
        id: 7000,
      };
      this.petBreeds.push(tag);
      this.breed = {value: 7000, name: newTag};
    },
    petTypeOptions() {
      const parsedPetTypes = this.petTypes.map((petType) => ({
        value: petType.id,
        name: petType.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetTypes];
    },
    async getPetBreeds() {
      let url = "pet_breeds";
      if (this.petTypeId > 0) {
        url += "?pet_type_id=" + this.petTypeId;
      }
      this.petBreeds = await window.Http.get(url);
    },
    async getPetBreed() {
      var id = parseInt(this.form.pet_breed_id);

      if (id > 0) {
        var pet_breed = await window.Http.get("pet_breeds/" + id);

        this.breed = {name: pet_breed.value, value: pet_breed.id};
      }
    },
    deltaDate(input, days, months, years) {
      return new Date(
          input.getFullYear() + years,
          input.getMonth() + months,
          Math.min(
              input.getDate() + days,
              new Date(
                  input.getFullYear() + years,
                  input.getMonth() + months + 1,
                  0
              ).getDate()
          )
      );
    },
    name({name}) {
      return `${name}`;
    },
    setInitialAgeAndDob() {
      if (!this.pet.dob) return null;

      this.dobValue = this.pet.dob;
      this.loadedDob = true;

      // var dob = new Date(this.form.dob);
      // var diff_ms = Date.now() - dob.getTime();
      // var age_dt = new Date(diff_ms);

      // this.ageValue = Math.abs(age_dt.getUTCFullYear() - 1970);
    },
  },
  async beforeMount() {
    console.log(this.pet);
    await this.getPetBreed();
    this.petTypeId = this.form.pet_type_id;
    this.setInitialAgeAndDob();
    this.petTypes = await Pet.getPetTypes();
    this.petTypeOptionsArray = await this.petTypeOptions();
  },
  computed: {
    ageValue: {
      get() {
        return this.age;
      },
      set(value) {
        this.age = value;

        //set the dob
        this.dobValue = this.deltaDate(new Date(), 0, 0, 0 - value);
      },
    },
    dobValue: {
      get() {
        return this.dob;
      },
      set(value) {
        this.dob = value;

        //calculate age
        var date = new Date(value);
        var diff_ms = Date.now() - date.getTime();
        var age_dt = new Date(diff_ms);

        this.age = Math.abs(age_dt.getUTCFullYear() - 1970);
      },
    },
    petBreedOptions() {
      const parsedPetBreeds = this.petBreeds.map((petBreed) => ({
        value: petBreed.id,
        name: petBreed.value,
      }));

      return [{value: null, name: "Unknown"}, ...parsedPetBreeds];
    },
  },
};
</script>

<style>
/* .multiselect__tags {
  @apply .h-12 .bg-gray-100 .border .w-full .rounded .py-2 .px-4 .text-gray-700;
}
.multiselect__tags:focus-within {
  @apply .border-purple-500;
}
.multiselect__input {
  @apply .bg-gray-100;
}
.multiselect__single {
  @apply .bg-gray-100;
} */
</style>