<template>
    <div>
        <base-panel title="New Medication" class="w-full max-w-4xl mx-auto">
            <medication-create></medication-create>
        </base-panel>
    </div>
</template>

<script>
    import MedicationCreate from "../../components/admin/MedicationCreate";

    export default {
        components: {
            MedicationCreate
        },
        props: {
            pet_id: Number
        },
        data() {
            return {
                loading: false
            }
        },
    }
</script>

<style scoped>

</style>
