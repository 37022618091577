<template>
  <div
      id="pet-details-results-table"
      class="w-full rounded overflow-hidden"
  >
    <div class="p-2 border-solid border-2 shadow-sm">
      <div class="flex">
        <div class="pet-details px-5" :class="showBurgerMenu ? 'w-1/6' : 'w-2/6'">
          <dog-icon v-if="pet.icon === 'dog'" class="fill-current w-16"></dog-icon>
          <cat-icon v-if="pet.icon === 'cat'" class="fill-current w-16"></cat-icon>
          <rabbit-icon v-if="pet.icon === 'rabbit'" class="fill-current w-16"></rabbit-icon>
          <hamster-icon v-if="pet.icon === 'hamster'" class="fill-current w-16"></hamster-icon>
          <horse-icon v-if="pet.icon === 'horse'" class="fill-current w-16"></horse-icon>
          <paw-icon v-if="pet.icon === 'paw'" class="fill-current w-16"></paw-icon>
          <div class="pet-properties">
            <div class="font-semibold capitalize">
              {{ pet.name || "unknown" }}
            </div>
            <div v-if="pet.pet_breed" class="capitalize">
              {{ pet.pet_breed.value }}
            </div>
            <div v-if="pet.sex" class="capitalize">
              {{ pet.sex }}
            </div>
            <div v-if="pet.dob">
              {{ this.calculateAge(pet.dob) }}
            </div>
          </div>
        </div>
        <div
            class="vaccines py-6 items-center text-center"
            :class="showBurgerMenu ? 'w-2/6' : 'w-5/6 mr-4'"
        >
          <div class="mb-2 flex justify-center">
            <span class="mr-2 font-semibold">Owner:</span>
            <router-link
                tag="tr"
                :to="{
                name: 'CustomerSummary',
                params: { id: pet.customers[0].id },
              }"
                class="cursor-pointer font-semibold"
            >
              <div id="customerlink" class="text-base">
                {{
                  pet.customers.length > 0 && pet.customers[0].first_name
                      ? pet.customers[0].first_name
                      : ""
                }}
                {{
                  pet.customers.length > 0 && pet.customers[0].last_name
                      ? pet.customers[0].last_name
                      : ""
                }}
              </div>
            </router-link
            >
          </div>
          <div
              class="next-vaccines border-2 border-light-blue-500 text-mp-blue font-semibold flex"
          >
            <p class="w-3/6">Next vaccinations due:</p>
            <span :class="[getVaccineClass(pet.vaccines.next_vaccinations.date)]"
            ><p class="ml-1">
                {{
                pet.vaccines.next_vaccinations.date
                    ? format(pet.vaccines.next_vaccinations.date)
                    : "Unknown"
              }}
              </p></span
            >
          </div>
          <div
              v-if="pet.pet_type_id == 1 || pet.pet_type_id == 2"
              class="kennel-cough-vaccines mt-4 border-2 border-light-blue-500 text-mp-blue font-semibold flex"
          >
            <p class="w-3/6">Next kennel cough due:</p>
            <span :class="[getVaccineClass(pet.vaccines.next_kennel_cough)]"
            ><p class="ml-1">
                {{
                pet.vaccines.next_kennel_cough
                    ? format(pet.vaccines.next_kennel_cough)
                    : "Unknown"
              }}
              </p></span
            >
          </div>
          <div
              v-if="pet.pet_type_id != 4"
              class="rabies-vaccines mt-4 border-2 border-light-blue-500 text-mp-blue font-semibold flex"
          >
            <p class="w-3/6">Next rabies due:</p>
            <span :class="[getVaccineClass(pet.vaccines.next_rabies)]"
            ><p class="ml-1">
                {{
                pet.vaccines.next_rabies
                    ? format(pet.vaccines.next_rabies)
                    : "Unknown"
              }}
              </p></span
            >
          </div>
        </div>
        <div
            class="burger-menu mr-2 ml-6"
            :class="showBurgerMenu ? 'w-3/6' : 'w-1/6'"
        >
          <div class="burger-menu-div cursor-pointer" @click="toggleBurgerMenu">
            <burger-menu-icon
                id="burger-image"
                class="fill-current w-8 h-6 ml-4"
            ></burger-menu-icon>
          </div>
          <div v-if="showBurgerMenu" class="menu mt-8">
            <base-button
                primary
                class="mb-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                @click="router.push({ name: 'PetEdit', params: { id: pet.id } })"
            >
              <span>Edit pet</span>
            </base-button>
            <base-button
                primary
                class="mb-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                @click="showConfirmDeleteModal"
            >
              <span>Delete pet</span>
            </base-button>
            <base-button
                primary
                class="mb-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                @click="router.push({ name: 'PetAudit', params: { id: pet.id } })"
            >
              <span>Audit</span>
            </base-button>
            <base-button
                primary
                class="mb-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                @click="exportData"
            >
              <span>Export</span>
            </base-button>
          </div>
        </div>
      </div>
      <div class="buttons flex mb-4 mt-4">
        <base-button
            primary
            v-if="!waitlisted"
            class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
            @click="addToWaitlist(pet)"
        >
          <span>Add to waitlist</span>
        </base-button>
        <base-button
            primary
            v-if="waitlisted"
            class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
            @click="removeFromWaitlist(pet)"
        >
          <span>Remove from waitlist</span>
        </base-button>
        <base-button
            primary
            class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
            @click="
            router.push({ name: 'NewAppointment', params: { pet_id: pet.id } })
          "
        >
          <span>Add appointment</span>
        </base-button>
      </div>
    </div>
    <div class="py-2 border-solid border-2 mt-4 shadow-lg">
      <table class="w-full">
        <thead>
        <tr>
          <th
              class="text-lg font-LexendDeca text-mp-blue bg-mp-off-white"
              colspan="6"
          >
            Appointments
          </th>
        </tr>
        <tr
            class="text-sm tracking-wider font-LexendDeca text-mp-blue text-left bg-mp-off-white"
        >
          <th class="font-medium p-4">Time</th>
          <th class="font-medium p-4">Date</th>
          <th class="font-medium p-4">Notes</th>
          <th class="font-medium p-4">Medication</th>
        </tr>
        </thead>
        <tbody>
        <tr
            class="tablelink border-t cursor-pointer hover:bg-mp-sky-blue"
            :key="appointment.id"
            tag="tr"
            @click="viewAppointment(appointment)"
            v-for="appointment in appointments"
        >
          <td class="p-4">
            <div>
              {{
                new Date(appointment.occurrence).toLocaleTimeString([], {
                  timeStyle: "short",
                })
              }}
            </div>
          </td>
          <td class="p-4">
            <div>
              {{ new Date(appointment.occurrence).toLocaleDateString("en-GB") }}
            </div>
          </td>
          <td id="appointmentnotes" class="p-4">
            <div>
              {{ appointment.notes }}
            </div>
          </td>
          <td class="p-4">
            <div
                :key="batch.id"
                v-for="(batch, index) in appointment.batches"
                class="inline"
            >
              <span v-text="batch.medication.name"> </span>
              <span v-if="index < appointment.batches.length - 1">,&nbsp;</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Delete pet"
        deleteMessage="Are you sure?"
        @deleteRecordEvent="deletePet"
        @close="closeConfirmDeleteModal"
    ></confirm-delete>
    <appointment-summary-modal
        v-if="isAppointmentModalVisible && selectedAppointment"
        :pet="pet"
        :appointment="selectedAppointment"
        @openAppointmentEvent="goToAppointment"
        @close="closeAppointmentSummaryModal"
    >
    </appointment-summary-modal>
  </div>
</template>

<script>
import Waitlist from "../../models/Waitlist";
import Toast from "../../services/Toast";
import Swal from "sweetalert2";
import Pet from "../../models/Pet";
import ConfirmDelete from "../modals/ConfirmDelete";
import AppointmentSummaryModal from "../modals/AppointmentSummaryModal.vue";
import Appointment from "../../models/Appointment";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  computed: {
    waitlisted: {
      get() {
        return this.onWaitlist;
      },
      set(waitlistFlag) {
        this.onWaitlist = waitlistFlag;
      },
    },
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  data() {
    return {
      isAppointmentImagesVisible: false,
      isConfirmDeleteModalVisible: false,
      isAppointmentModalVisible: true,
      onWaitlist: false,
      showBurgerMenu: false,
      selectedAppointment: null,
      appointmentImages: [],
    };
  },
  props: {
    appointments: Array,
    pet: Object,
    vaccinations: Array,
  },
  components: {
    ConfirmDelete,
    AppointmentSummaryModal,
  },
  methods: {
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    async deletePet() {
      const customer_id = this.pet.customers[0].id;
      await this.pet.delete();

      await this.router.push({
        name: "CustomerSummary",
        params: {id: customer_id},
      });
    },
    exportData() {
      this.pet.exportData();
    },
    format(date) {
      let d;

      if (date instanceof Date) {
        d = date;
      } else {
        d = new Date(date);
      }

      return d.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    async addToWaitlist(pet) {
      const item = {pet_id: pet.id};

      const data = await Waitlist.create(item);

      if (data) {
        await Toast.fire({
          icon: "success",
          title: pet.name + " added to waitlist",
        });
      }

      this.waitlisted = true;
    },
    calculateAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(dateString);

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " years";
      else yearString = " year";
      if (age.months > 1) monthString = " months";
      else monthString = " month";
      if (age.days > 1) dayString = " days";
      else dayString = " day";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
            age.years +
            yearString +
            ", " +
            age.months +
            monthString +
            ", and " +
            age.days +
            dayString +
            " old.";
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "Only " + age.days + dayString + " old!";
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " old. Happy Birthday!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString =
            age.years + yearString + " and " + age.months + monthString + " old.";
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
            age.months + monthString + " and " + age.days + dayString + " old.";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString =
            age.years + yearString + " and " + age.days + dayString + " old.";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " old.";
      else ageString = "Oops! Could not calculate age!";

      return ageString;
    },
    getVaccineClass(date) {
      let vaccineDate = new Date(date);

      if (!this.isValidDate(vaccineDate)) {
        return "w-3/6 bg-red-400";
      }

      let now = new Date();

      if (vaccineDate < now) {
        return "w-3/6 bg-red-400";
      }

      const diffTime = Math.abs(now - vaccineDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 14) {
        return "w-3/6 bg-yellow-400";
      } else if (14 < diffDays < 365) {
        return "w-3/6 bg-green-400";
      }

      return "w-3/6 bg-white-400";
    },
    toggleBurgerMenu() {
      this.showBurgerMenu = !this.showBurgerMenu;
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    async removeFromWaitlist(pet) {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0074b5",
        cancelButtonColor: "#ad3c34",
        confirmButtonText: "Remove pet from waitlist",
      });

      if (!result.value) {
        return;
      }

      await Waitlist.delete(pet.id);

      this.waitlisted = false;
    },
    showAppointmentSummaryModal() {
      this.isAppointmentModalVisible = true;
    },
    closeAppointmentSummaryModal() {
      this.isAppointmentModalVisible = false;
    },
    async goToAppointment() {
      await this.router.push({
        name: "AppointmentDetails",
        params: {id: this.selectedAppointment.id},
      });
    },
    async viewAppointment(appointment) {
      const data = await Appointment.find(appointment.id);

      let medications = data.batches
          .map(function (b) {
            return b.medication.name;
          })
          .join();

      this.selectedAppointment = data;

      this.isAppointmentModalVisible = true;
    },
  },
  mounted() {
    if (this.pet.waitlists && this.pet.waitlists.length > 0) {
      this.onWaitlist = true;
    } else {
      this.onWaitlist = false;
    }
  },
};
</script>

<style scoped>
.burger-menu {
  position: relative;
}

#burger-image {
  position: absolute;
  top: 0px;
  right: 0px;
}

#customerlink {
  display: inline-block;
  background: #e0e0e0;
  padding: 0 12px;
  border-radius: 32px;
}

#customerlink:hover {
  background: #ccc;
  font-size: 1.125rem;
}
</style>