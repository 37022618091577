<template>
  <div>
    <base-panel title="Change password" class="w-full max-w-4xl mx-auto">
      <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
      <password-change :team="team" v-else></password-change>
    </base-panel>
  </div>
</template>

<script>
import AccountSettings from "../../components/admin/AccountSettings";
import Team from "../../models/Team";
import PasswordChange from "../../components/admin/PasswordChange";

export default {
  async beforeMount() {
    await this.getTeam();
  },
  components: {PasswordChange, AccountSettings},
  data() {
    return {
      team: Object,
      loading: false,
    }
  },
  methods: {
    async getTeam() {
      this.loading = true;
      try {
        this.team = await Team.getById(this.$auth.user().team_id);
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false
      }
    },
  },

}
</script>

<style scoped>

</style>
