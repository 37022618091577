import AppointmentDetails from "../pages/admin/AppointmentDetails.vue";
import Appointments from "../pages/admin/Appointments.vue";
import AccountSettings from "../pages/admin/AccountSettings.vue";
import AppointmentSummary from "../pages/admin/AppointmentSummary.vue";
import ChangePassword from "../pages/admin/PasswordChange.vue";
import OnboardVeterinaryClinic from "../components/admin/OnboardVeterinaryClinic.vue";
import CustomerDetails from "../pages/admin/CustomerDetails.vue";
import CustomerSummary from "../pages/admin/CustomerSummary.vue";
import Customers from "../pages/admin/Customers.vue";
import Login from "../pages/admin/Login.vue";
import Medications from "../pages/admin/Medications.vue";
import MicrochipRegistration from "../pages/MicrochipRegistration.vue";
import MicrochipRegistrationHistory from "../pages/MicrochipRegistrationHistory.vue";
import MicrochipSearch from "../pages/MicrochipSearch.vue";
import MicrochipBatchAdd from "../pages/MicrochipBatchAdd.vue";
import MedicationSummary from "../pages/admin/MedicationSummary.vue";
import NewAppointment from "../pages/admin/NewAppointment.vue";
import NewCustomer from "../pages/NewCustomer.vue";
import NewMedication from "../pages/admin/NewMedication.vue";
import NewPet from "../pages/NewPet.vue";
import Pets from "../pages/admin/Pets.vue";
import PetAudit from "../pages/admin/PetAudit.vue";
import PetEdit from "../pages/admin/PetEdit.vue";
import PetSummary from "../pages/admin/PetSummary.vue";
import PasswordReset from "../pages/admin/PasswordReset.vue";
import PasswordResetRequest from "../pages/admin/PasswordResetRequest.vue";
import Settings from "../pages/admin/Settings.vue";
import AdminPanel from "../pages/admin/AdminPanel.vue";
import VisitorRegistrationComplete from "../pages/VisitorRegistrationComplete.vue";
import Waitlist from "../pages/admin/Waitlist.vue";

export default [
  {
    path: "/",
    redirect: "/customers",
    meta: {
      requiresAuth: true,
      hideBackButton: true,
    }
  },
  {
    path: "/account-settings/:id",
    name: "AccountSettings",
    component: AccountSettings,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: false,
      hideBackButton: true,
    }
  },
  {
    path: "/change-password/:id",
    name: "ChangePassword",
    component: ChangePassword,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/onboard-veterinary-clinic",
    name: "OnboardVeterinaryClinic",
    component: OnboardVeterinaryClinic,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/new-customer",
    name: "NewCustomer",
    component: NewCustomer,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/customer/:id",
    name: "CustomerDetails",
    component: CustomerDetails,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/appointment/:id",
    name: "AppointmentDetails",
    component: AppointmentDetails,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/appointments/:id",
    name: "Appointments",
    component: Appointments,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/customer-summary/:id",
    name: "CustomerSummary",
    component: CustomerSummary,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pets",
    component: Pets,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pet/:id",
    name: "PetEdit",
    component: PetEdit,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pet-audit/:id",
    name: "PetAudit",
    component: PetAudit,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pet-summary/:id",
    name: "PetSummary",
    component: PetSummary,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/new-pet/:customer_id",
    name: "NewPet",
    component: NewPet,
    props: (route) => ({
      name: route.query.name,
      customer_id: parseInt(route.params.customer_id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/reset-password-request",
    name: "PasswordResetRequest",
    component: PasswordResetRequest,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/medications",
    name: "Medications",
    component: Medications,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/microchip-registration",
    name: "MicrochipRegistration",
    component: MicrochipRegistration,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/microchip-registration-history",
    name: "MicrochipRegistrationHistory",
    component: MicrochipRegistrationHistory,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/microchip-search",
    name: "MicrochipSearch",
    component: MicrochipSearch,
    props: (route) => ({ name: route.query.name }),
  },
  {
    path: "/microchip-add-batches",
    name: "MicrochipBatchAdd",
    component: MicrochipBatchAdd,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/medication-summary/:id",
    name: "MedicationSummary",
    component: MedicationSummary,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
      meta: {
        requiresAuth: true,
      }
    }),
  },
  {
    path: "/new-medication",
    name: "NewMedication",
    component: NewMedication,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/new-appointment/:pet_id",
    name: "NewAppointment",
    component: NewAppointment,
    props: (route) => ({
      name: route.query.name,
      pet_id: parseInt(route.params.pet_id),
      meta: {
      requiresAuth: true,
    }
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/appointment-summary/:id",
    name: "AppointmentSummary",
    component: AppointmentSummary,
    props: (route) => ({
      name: route.query.name,
      id: parseInt(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/admin-panel",
    name: "AdminPanel",
    component: AdminPanel,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/visitor-registration-complete",
    name: "VisitorRegistrationComplete",
    component: VisitorRegistrationComplete,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/waitlist",
    component: Waitlist,
    props: (route) => ({ name: route.query.name }),
    meta: {
      requiresAuth: true,
    }
  },
];
