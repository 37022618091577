<template>
    <div class="w-full flex justify-center items-center">
        <div class="bg-white border-l-8 border-mp-red mt-4 rounded-lg w-full shadow-lg">
            <div class="w-full flex">
                <div class="w-1/6 pt-6 flex justify-center">
                </div>
                <div class="w-full pt-9 pr-4">
                    <h3 class="font-bold text-mp-red">Microchip not found</h3>
                    <p class="py-4 text-sm text-gray-400">This microchip isn’t registered. We advise you also check
                        EuroPetNet.
                        <a href="https://www.europetnet.com" target="_blank" rel="noreferrer" class="text-mp-blue">
                            https://www.europetnet.com/
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block text-mp-blue"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                            </svg>
                        </a>
                        If you locate the owner by other means, they can register with MyPet for free. Just tell them to
                        head to
                        <a href="https://mypethq.io" target="_blank" rel="noreferrer" class="text-mp-blue">mypethq.io
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block text-mp-blue"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                            </svg>
                        </a> and use coupon code MyVet2022.
                    </p>
                </div>
            </div>
            <div class="p-4 flex space-x-4">
                <a href="#" @click="$emit('resetPage')"
                   class="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Reset</a>
                <a href="#" @click="$emit('searchAgain')"
                   class="w-1/2 px-4 py-3 text-center text-white bg-mp-dark-green rounded-lg hover:bg-mp-hover-blue hover:text-white font-bold text-sm">Search
                    again</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>