<template>
    <div>
        <base-panel title="New Appointment" class="w-full max-w-4xl mx-auto">
            <appointment-create :pet_id="pet_id"></appointment-create>
        </base-panel>
    </div>
</template>

<script>
    import AppointmentCreate from "../../components/admin/AppointmentCreate";

    export default {
        components: {
            AppointmentCreate
        },
        props: {
            pet_id: Number
        },
        data() {
            return {
                loading: false
            }
        },
    }
</script>

<style scoped>

</style>
