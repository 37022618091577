<template>
    <base-panel title="Medications" id="appointment-medication-table" no-padding>
        <table class="w-full">
            <tbody>
                <router-link
                        tag="tr"
                        v-for="medication in appointment.medications"
                        :key="medication.id"
                        :to="{ name: 'MedicationSummary', params: { id: pet.id } }"
                        class="hover:bg-gray-100 cursor-pointer"
                >
                    <td class="p-4 w-px">
                        <user-icon class="fill-current w-5"></user-icon>
                    </td>
                    <td class="p-4 pl-0 text-lg">
                        {{ medication.name }}
                    </td>
                </router-link>
            </tbody>
        </table>
    </base-panel>
</template>

<script>
    export default {
        props: {
            id: Number,
            appointment: Object
        },
    }
</script>

<style scoped>

</style>
