<template>
  <div>
    <base-panel title="Waitlist" no-padding>
      <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
      <waitlist-results-table :waitlist="waitlist" @delete="getWaitlist" v-else></waitlist-results-table>
    </base-panel>
  </div>
</template>

<script>
import WaitlistResultsTable from '../../components/admin/WaitlistResultsTable.vue'
import Waitlist from "../../models/Waitlist";

export default {
  name: 'app',
  components: {
    WaitlistResultsTable
  },
  data() {
    return {
      waitlist: [],
      loading: false,
    }
  },
  methods: {
    async getWaitlist() {
      this.loading = true
      try {
        this.waitlist = await Waitlist.all();
      } catch(error){
          await this.$auth.logout();
          this.router.push({ name: "Login" });
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
  },
  beforeMount(){
    this.getWaitlist()
  },
}
</script>
