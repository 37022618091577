<template>
    <div class="w-full max-w-4xl mx-auto mt-8">
        <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
        <div v-else class="flex items-start">
            <customer-summary-table class="flex-none" :customer="customer"></customer-summary-table>
<!--            <customer-pets-table class="flex-none" :customer="customer"></customer-pets-table>-->
        </div>
    </div>
</template>

<script>
    import CustomerSummaryTable from "../../components/admin/CustomerSummaryTable";
    import CustomerPetsTable from "../../components/admin/CustomerPetsTable";
    import Customer from "../../models/Customer";
    import Swal from 'sweetalert2'

    export default {
        props: {
            id: Number
        },
        components: {
            CustomerSummaryTable,
            CustomerPetsTable
        },
        data() {
            return {
                customer: '',
                loading: false,
                pets_loading: false
            }
        },
        methods: {
            async getCustomer() {
                this.loading = true
                try {
                    this.customer = await Customer.getById(this.id);
                } catch(error){
                    await this.$auth.logout();
                    this.router.push({ name: "Login" });
                } finally {
                    this.loading = false
                }
            },
            async delete_customer () {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "This change is not reversible!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Delete customer and all pets?'
                })

                if (! result.value) {
                    return;
                }

                await Customer.delete(this.customer);
                this.router.push({name: 'Customers'})

                // await window.Http.delete('Queue?id=' + pet.id)
                //
                // let index = this.waitlist.indexOf(pet)
                // this.waitlist.splice(index, 1)
            },
        },
        mounted() {
        },
        beforeMount(){
            this.getCustomer();
        },
    }
</script>

<style scoped>

</style>
