<template>
    <div id="pet-audit-table">
        <table class="w-full">
            <thead>
                <tr class="uppercase text-sm tracking-wider text-gray-500 text-left bg-gray-100">
                    <th class="font-medium p-4 w-px" style="width:30%">Date</th>
                    <th class="font-medium p-4" style="width:35%">Medication</th>
                    <th class="font-medium p-4" style="width:35%">Batch</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="{ appointment, batch } in filteredBatchesFromAppointments" :key="appointment.id + '_' + batch.id" class="border-t">
                    <td class="p-4">
                        <div class="capitalize font-semibold text-lg">
                            {{ format(appointment.occurrence) || 'Unknown' }}
                        </div>
                    </td>
                    <td class="p-4">
                        <div class="capitalize font-semibold text-lg">
                            {{ batch.medication.name || '-' }}
                        </div>
                    </td>
                    <td class="p-4">
                        <div class="capitalize font-semibold text-lg">
                            {{ batch.value || '-' }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Appointment from "../../models/Appointment";

    export default {
        components: {
        },
        props: {
            pet: Object,
            search: String,
        },
        data () {
            return {
                breed: null,
                appointments: [],
                petBreeds: [],
                form: new window.Form (this.pet.getAttributes(), async form => {
                    if(this.breed.value){form.pet_breed_id = this.breed.value}
                    this.pet.update(form)
                   this.router.push({ name: 'PetSummary', params: { id: this.pet.id } })
                }),
            }
        },
        methods:{
            async getAppointments() {
                this.loading = true
                try {
                    this.appointments = await Appointment.findbyPet(this.pet.id)
                } finally {
                    this.loading = false
                }
            },
            format(date){
                var d;

                if(date instanceof Date) { d = date } else { d = new Date(date) }

                return d.toLocaleDateString('en-GB', {
                    day : 'numeric',
                    month : 'numeric',
                    year : 'numeric'
                })
            },
        },
        computed: {
            allBatchesFromAppointments () {
                return this.appointments.flatMap(appointment => {
                    return appointment.batches.map(batch => ({ appointment, batch }))
                })
            },
            filteredBatchesFromAppointments () {
                if (!this.search) return this.allBatchesFromAppointments;

                return this.allBatchesFromAppointments.filter(({ batch }) => {
                    if (this.search === '') return true;

                    const batchmatch = batch.medication.name.toLowerCase().includes(this.search.toLowerCase());

                    return batchmatch || batch.value.toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },
        beforeMount() {
            this.getAppointments()
        }
    }
</script>

<style>
</style>
