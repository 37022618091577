<template>
  <div
      class="
      bg-mp-off-white
      h-18
      flex
      justify-center
      w-full
      border border-mp-blue
    "
      @mouseleave="$emit('setMenuItemCategory', '')"
  >
    <div class="ml-12">
      <router-link
          class="
          mt-6
          mb-6
          inline-flex
          items-center
          font-LexendDeca
          text-base text-mp-blue
        "
          v-for="item in items"
          :key="item.id"
          :to="{
          name: item.routeName,
        }"
          @click.native="emitCategoryEvent"
      >
        <span class="navlink">{{ item.name }}</span>
        <p v-if="item.id != items.length" class="ml-6 mr-6">|</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    emitCategoryEvent() {
      this.$emit('setMenuItemCategory', '');
    },
  },
};
</script>

<style scoped>
</style>