import Swal from "sweetalert2";
<template>
    <div class="w-full max-w-4xl mx-auto">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <div v-else class="flex items-start">
            <appointment-summary-table :appointment="appointment" ></appointment-summary-table>
            <appointment-medication-table class="ml-5" :appointment="appointment" style="margin-top:2rem"></appointment-medication-table>
            </div>
    </div>
</template>

<script>
    import AppointmentSummaryTable from "../../components/admin/AppointmentSummaryTable";
    import Appointment from "../../models/Appointment";
    import AppointmentMedicationTable from "../../components/admin/AppointmentMedicationTable";

    export default {
        props: {
          id: Number
        },
        components: {
            AppointmentSummaryTable,
            AppointmentMedicationTable
        },
        data() {
            return {
                appointment: {},
                loading: false
            }
        },
        methods: {
            async getAppointment() {
                this.loading = true
                try {
                    this.appointment = await Appointment.find(this.id)
                } catch(error){
                    await this.$auth.logout();
                    this.router.push({ name: "Login" });
                } finally {
                    this.loading = false
                }
            },
        },
        mounted() {

        },
        beforeMount(){
            this.getAppointment();
        },
    }
</script>

<style scoped>

</style>
