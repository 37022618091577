<template>
  <base-panel id="appointment-summary-table mt-20" style="margin-top:2rem">
    <div class="p-4">
      <base-horizontal-field label="Date">
        {{ formatDate(appointment.created_at) }}
      </base-horizontal-field>
      <base-horizontal-field label="Notes">
        {{ appointment.notes }}
      </base-horizontal-field>
      <base-button primary class="ml-2 mr-2 bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                   @click="router.push({ name: 'AppointmentDetails', params: { id: appointment.id } })">
        <edit-icon icon="edit" class="fill-current w-4 h-4 mr-2"></edit-icon>
        <span>Edit</span>
      </base-button>
    </div>
  </base-panel>
</template>

<script>
import EditIcon from "../shared/icons/EditIcon"
import BaseHorizontalField from "../shared/BaseHorizontalField";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  components: {BaseHorizontalField, EditIcon},
  props: {
    appointment: Object
  },
  data() {
    return {
      form: new window.Form(this.appointment.getAttributes(), async form => {
        this.appointment.update(form)
      })
    }
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    formatDate(value) {

      var date = new Date(value);

      return date.toLocaleString()
    }
  }
}
</script>

<style scoped>

</style>
