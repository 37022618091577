<template>
    <div class="w-full max-w-4xl mx-auto">
        <base-panel title="Appointment Details" class="mb-8">
            <div v-if="loading" class="flex h-16 items-center justify-center font-semibold text-gray-600">Loading...</div>
            <appointment-update :appointment="appointment" :pet="pet" v-else></appointment-update>
        </base-panel>
    </div>
</template>

<script>
    import AppointmentUpdate from "../../components/admin/AppointmentUpdate";
    import Appointment from "../../models/Appointment";
    import Pet from "../../models/Pet";

    export default {
        props: {
          id: Number
        },
        components: {
            AppointmentUpdate
        },
        data() {
            return {
                appointment: '',
                pet: '',
                loading: false,
            }
        },
        methods: {
            async getAppointment() {
                this.loading = true
                try {
                    this.appointment = await Appointment.find(this.id);
                    this.pet = await Pet.find(this.appointment.pet_id);
                } catch(error){
                    await this.$auth.logout();
                    this.router.push({ name: "Login" });
                } finally {
                    this.loading = false
                }
            },
        },
        mounted() {
        },
        beforeMount(){
            this.getAppointment();
        },
    }
</script>

<style scoped>

</style>
