<template>
  <div>
    <base-horizontal-field>
      <h1 class="font-semibold text-lg text-gray-800">
        Onboard a new customer
      </h1>
      only adds new team, can't use this for adding additional users to existing
      team
    </base-horizontal-field>
    <base-horizontal-field label="Team name" class="mt-2">
      <base-input
          v-model="form.team_name"
          :error="form.errors.get('team_name')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="User name" class="mt-2">
      <base-input
          v-model="form.user_name"
          :error="form.errors.get('user_name')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Email" class="mt-2">
      <base-input
          v-model="form.email"
          :error="form.errors.get('email')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Password" class="mt-2">
      <base-input
          v-model="form.password"
          :error="form.errors.get('password')"
      ></base-input>
    </base-horizontal-field>
    <base-horizontal-field label="Allow batch management" class="mt-2">
      <label class="form-checkbox">
        <input
            type="checkbox"
            v-model="form.allow_batch_management"
            class="form-checkbox h-5 w-5 text-mp-blue"
        />
      </label>
    </base-horizontal-field>
    <base-horizontal-field>
      <div class="flex justify-end">
        <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
        <base-button
            primary
            @click="form.submit()"
            class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
        >Save
        </base-button
        >
      </div>
    </base-horizontal-field>
  </div>
</template>

<script>
import BaseHorizontalField from "../shared/BaseHorizontalField";
import SuperAdmin from "../../models/SuperAdmin";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  async beforeMount() {
    await this.getUser();
  },
  components: {BaseHorizontalField},
  data() {
    const initialData = {
      team_name: "",
      user_name: "",
      email: "",
      password: "",
      allow_batch_management: false,
    };

    return {
      user: Object,
      form: new window.Form(initialData, async (form) => {
        await SuperAdmin.onboardTeam(form);

        location.reload();
      }),
    };
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async getUser() {
      this.user = this.$auth.user();
    },
  },
  props: {
    id: Number,
  },
};
</script>

<style scoped>
</style>
