<template>
  <img :src="src" alt="horse" />
</template>

<script>

import src from "../../../../resources/icons/horse.svg";

export default {
  setup() {
    return {
      src
    };
  }
}
</script>
