<template>
  <div>
    <base-panel title="Medications" no-padding>
      <template #search>
        <input
            class="ml-3 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal mr-5"
            v-model="search"
            type="text"
            placeholder="Search medication name"
            @input="searchMedications()"
        />
      </template>
      <template #icon>
        <div
            id="add-medication-div"
            @click="router.push({ name: 'NewMedication' })"
        >
          <plus-circle-icon
              icon="plus-circle"
              class="fill-current w-8 h-8 mr-4"
          ></plus-circle-icon>
        </div>
      </template>
      <div
          v-if="loading"
          class="flex h-16 items-center justify-center font-semibold text-gray-600"
      >
        Loading...
      </div>
      <medications-results-table
          :medications="medications"
          @add="searchMedications"
      ></medications-results-table>
    </base-panel>
  </div>
</template>

<script>
import MedicationsResultsTable from "../../components/admin/MedicationsResultsTable.vue";
import Medication from "../../models/Medication";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: "app",
  components: {
    MedicationsResultsTable,
  },
  data() {
    return {
      search: "",
      medications: [],
      loading: false,
    };
  },
  setup() {
    const router = useRouter();

    return {
      router
    }
  },
  methods: {
    async searchMedications() {
      this.loading = true;
      try {
        const meds = await Medication.search(this.search);
        this.medications = meds.data;
      } catch (error) {
        await this.$auth.logout();
        this.router.push({name: "Login"});
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.searchMedications();
  },
};
</script>

<style scoped>
#add-medication-div {
  cursor: pointer;
}
</style>