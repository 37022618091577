import {reactive} from 'vue'

export default {
    state: reactive({
        user: null,
        ready: false,
    }),

    user() {
        return this.state.user;
    },

    check() {
        return !!this.state.user;
    },

    ready() {
        return this.state.ready;
    },

    role(role) {
        return this.state.user.role === role;
    },

    setUser(user) {
        this.state.user = user;
    },

    async init() {
        await this.refresh();
        this.state.ready = true;
    },

    async refresh() {
        try {
            const user = await window.Http.get("me");
            this.setUser(user);
        } catch (e) {
            this.setUser(null);
        }
    },

    async login(form) {
        const user = await window.Http.post("login", form);
        this.setUser(user);
    },

    async tokenLogin(form) {
        const user = await window.Http.post("token-login", form);

        this.setUser(user);
    },

    async logout() {
        await window.Http.post("logout");
        this.setUser(null);
    },
};
