<template>
  <div id="microchip-registration-history-body" class="bg-mp-off-white" style="overflow:auto;">
    <table class="w-full bg-white table-centered">
      <thead>
      <tr
          class="
            text-sm
            tracking-wider
            text-xl
            font-LexendDeca
            text-mp-blue text-left
            bg-mp-off-white
          "
      >
        <th>Email/number</th>
        <th>Microchip</th>
        <th>Sent</th>
        <th>Delivered</th>
        <th>Owner confirmed</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="request in requests" :key="request.id">
        <td class="p-4">{{ request.email ?? request.mobile }}</td>
        <td class="p-4">{{ request.microchip }}</td>
        <td class="p-4">
          <div class="flex justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-green"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
              />
            </svg>
          </div>
        </td>
        <td>
          <div
              v-if="!!request.email"
              class="flex justify-center"
              @click.stop="showResendEmailModal(request.id)"
          >
            <svg
                v-if="(request.latest_email && request.latest_email.delivered_at)"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-green"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
              />
            </svg>
            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-red cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </div>
          <div
              v-if="!request.email"
              class="flex justify-center"
          >
            <svg
                v-if="(request.latest_sms && request.latest_sms.delivered_at)"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-green"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
              />
            </svg>
            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-red cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </div>
          <p v-if="!!request.email" class="text-xs">Click to resend</p>
        </td>
        <td class="p-4">
          <div class="flex justify-center">
            <svg
                v-if="request.status === 'completed'"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-green"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
              />
            </svg>
            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-mp-red"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <table-pagination
        :pageSize="perPage"
        :pageNumber="page"
        :resultsSize="resultsSize"
        @switchPage="switchPage"
    ></table-pagination>
    <resend-email-modal
        v-show="isResendEmailModalVisible"
        :modalHeadline="'Resend registration email'"
        :request-id="selectedRequestId"
        message="Enter a new email address and we will send a microchip registration email to the new user"
        @confirmationEvent="resendEmail"
        @close="closeResendEmailModal"
        value=""></resend-email-modal>
  </div>
</template>

<script>
import TablePagination from "../shared/TablePagination.vue";
import Toast from "../../services/Toast";
import Microchip from "../../models/Microchip";
import ResendEmailModal from "../../components/modals/ResendEmailModal.vue";

export default {
  components: {
    ResendEmailModal,
    TablePagination,
  },
  props: {
    resultsSize: 0,
    requests: Array,
    search: String,
  },

  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      isResendEmailModalVisible: false,
      selectedRequestId: 0,
      page: 1,
      perPage: 10,
      pages: [],
      requestId: 0,
    };
  },

  methods: {
    async listMicrochipRegistrationRequests() {
      await this.$emit("listMicrochipRegistrationRequests", {
        search: this.search,
        page: this.page,
        perPage: this.perPage,
      });
    },
    async showResendEmailModal(requestId) {
      console.log("Request ID:", requestId);
      this.selectedRequestId = requestId;
      this.isResendEmailModalVisible = true;
    },
    async closeResendEmailModal() {
      this.isResendEmailModalVisible = false;
    },
    async switchPage(payload) {
      this.page = payload.page;
      await this.listMicrochipRegistrationRequests();
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.requests.length)
        this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    async resendEmail(payload) {
      await this.closeResendEmailModal();

      await Toast.fire({
        timer: 5000,
        icon: "success",
        title:
            "Thanks - we have resent this email for you! It can take a few minutes to re-deliver your email. Please refresh your search to see updates.",
      });

      if (payload.resendToExisting) {
        await Microchip.resendRegistrationRequestEmail(payload.requestId);
      } else {
        await Microchip.resendRegistrationRequestEmail(
            payload.requestId,
            payload.email
        );
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));

      await this.listMicrochipRegistrationRequests();
    },
  },
  computed: {},
};
</script>

<style scoped>
th,
td {
  padding: 15px;
}

@media (max-width: 600px) {
  /* Adjust styles for mobile */
  .table-centered th, .table-centered td {
    padding: 10px;
  }
}

/* .table-centered {
  text-align: center;
  vertical-align: middle;
} */

button.page-link {
  display: inline-block;
}

button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}

.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>