<template>
  <div>
    <base-panel title="Admin" no-padding>
      <div
        v-if="loading"
        class="
          flex
          h-16
          items-center
          justify-center
          font-semibold
          text-gray-600
        "
      >
        Loading...
      </div>
      <admin-options-table></admin-options-table>
    </base-panel>
  </div>
</template>

<script>
import AdminOptionsTable from "../../components/admin/AdminOptionsTable.vue";

export default {
  components: {
    AdminOptionsTable,
  },
  data() {
    return {
      pets: [],
      loading: false,
    };
  },
  methods: {},
  mounted() {},
  beforeMount() {},
};
</script>
