<template>
    <div id="appointment-update">
        <base-wide-horizontal-field label="Pet name">
            <p>{{ pet.name }}</p>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field label="Date">
            <vc-date-picker v-model="form.occurrence" mode="dateTime">
                <template v-slot="{ inputValue, inputEvents, hidePopover }">
                    <input
                            class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
                            :value="inputValue"
                            v-on="inputEvents"
                            @keyup.enter.tab="hidePopover"
                    />
                </template>
            </vc-date-picker>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field label="Service">
            <base-toggle
                    v-model="form.service"
                    :options="[
          { text: 'Consult', value: 'consult' },
          { text: 'Follow up consult', value: 'follow_up_consult' },
          { text: 'No consult', value: 'no_consult' },
        ]"
            ></base-toggle>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field label="Medications">
            <div class="flex">
                <div class="w-3/6 font-semibold">Name</div>
                <div class="font-semibold w-1/6 ml-3">Quantity</div>
                <div class="font-semibold w-1/6 ml-3">Item cost</div>
                <div class="font-semibold w-1/6 ml-3">Price</div>
                <div class="font-semibold w-1/6"></div>
            </div>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field>
            <div id="medicationList" v-for="med in medicationRows" :key="med.id">
                <div :id="med.id" class="flex justify-center items-center ali mt-2">
                    <multiselect
                            v-model="med.name"
                            :options="medicationOptions"
                            placeholder="unknown"
                            label="name"
                            track-by="name"
                            :multiple="false"
                            :preserve-search="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            class="w-3/6"
                            @close="selectMedicationOption(med)"
                    ></multiselect>
                    <input
                            class="w-1/6 ml-3 rounded border border-gray-200"
                            type="number"
                            v-model="med.quantity"
                            @change="getTotalCost()"
                            min="0"
                            step="1"
                            max="1000"
                    />
                    <input
                            class="w-1/6 ml-3 rounded border border-gray-200"
                            type="number"
                            name="cost-per-item"
                            v-model="med.costPerItem"
                            @change="getTotalCost()"
                            min="0"
                            step="0.01"
                            max="1000"
                    />
                    <input
                            class="w-1/6 ml-3 rounded border border-gray-200"
                            type="number"
                            name="cost"
                            v-model="med.totalCost"
                            min="0"
                            max="1000"
                    />
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#ab3b42"
                            class="w-1/6 w-5 h-5 mt-3 cursor-pointer"
                            @click="removeItem(med.id)"
                    >
                        <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                </div>
            </div>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field>
            <div class="flex cursor-pointer w-2/6" @click="addMedicationComponent">
                <button
                        class="w-8 h-8 bg-mp-green rounded-full hover:bg-green-600 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                >
                    <svg
                            viewBox="0 0 20 20"
                            enable-background="new 0 0 20 20"
                            class="w-6 h-6 inline-block"
                    >
                        <path
                                fill="#FFFFFF"
                                d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                                    C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                                    C15.952,9,16,9.447,16,10z"
                        />
                    </svg>
                </button>
                <label class="cursor-pointer ml-2 mt-1 font-semibold"
                >Add another</label
                >
            </div>
        </base-wide-horizontal-field>
        <base-wide-horizontal-field label="Notes">
      <textarea
              v-model="form.notes"
              class="h-40 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500"
      ></textarea>
        </base-wide-horizontal-field>
        <div>
            <div id="base64ImageList" v-for="i in base64ImageObjects" :key="i.id">
                <img v-if="showBase64Images" :id="i.id" :src="i.base64code"/>
            </div>
        </div>
        <base-wide-horizontal-field label="Images">
            <label
                    class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-mp-blue hover:text-white"
            >
                <svg
                        class="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                >
                    <path
                            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                </svg>
                <span class="mt-2 text-base leading-normal">Select a file</span>
                <input type="file" @change="onFileChange" class="hidden"/>
            </label>
        </base-wide-horizontal-field>
        <div id="appointment-update-gallery" class="grid grid-cols-3 gap-4">
            <div v-for="(image, key) in savedImages"
                 :key="key">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        viewBox="0 0 24 24"
                        stroke="#ab3b42"
                        class="w-8 h-8 cursor-pointer"
                        @click.prevent.stop="removeImage(image)"
                >
                    <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                <a
                        :href="image.largeURL"
                        :data-pswp-width="image.width"
                        :data-pswp-height="image.height"
                >

                    <div class="image-container">
                        <img class="image" :src="image.thumbnailURL" alt=""/>
                    </div>
                </a>
            </div>
        </div>
        <base-wide-horizontal-field label="Discount">
            <input
                    class="w-2/6 rounded border border-gray-200"
                    type="number"
                    v-model="discount"
                    name="discount"
                    min="0"
                    step="0.01"
                    max="1000"
            />
        </base-wide-horizontal-field>
        <base-wide-horizontal-field label="Total cost">
            <input
                    disabled
                    class="w-2/6 rounded border border-gray-200"
                    type="number"
                    v-model="totalCost"
                    name="total_cost"
                    min="0"
                    step="0.01"
                    max="1000"
            />
        </base-wide-horizontal-field>
        <base-wide-horizontal-field>
            <div class="flex justify-end">
                <base-button @click="router.go(-1)" class="mr-4">Back</base-button>
                <base-button
                        primary
                        @click="form.submit()"
                        class="bg-mp-blue text-mp-off-white hover:bg-mp-hover-blue"
                >Update
                </base-button
                >
            </div>
        </base-wide-horizontal-field>
        <confirm-delete
                v-show="isConfirmDeleteModalVisible"
                :modalHeadline="'Delete selected image?'"
                deleteMessage="Are you sure?"
                @deleteRecordEvent="deleteImage"
                @close="closeConfirmDeleteModal"
        ></confirm-delete>
    </div>
</template>

<script>
import 'v-calendar/dist/style.css';
import BaseWideHorizontalField from "../shared/BaseWideHorizontalField";
import BaseHorizontalField from "../shared/BaseHorizontalField";
import Multiselect from "vue-multiselect";
import Medication from "../../models/Medication";
import axios from "axios";
import Appointment from "../../models/Appointment";
import ConfirmDelete from "../modals/ConfirmDelete";
import {useRouter} from "vue-router/dist/vue-router";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
    props: {
        id: Number,
        pet: Object,
        appointment: Object,
    },
    components: {
        BaseWideHorizontalField,
        BaseHorizontalField,
        Multiselect,
        ConfirmDelete,
    },
    setup() {
        const router = useRouter();

        return {
            router
        }
    },
    data() {
        return {
            images: [],
            selectedItem: Object,
            isConfirmDeleteModalVisible: false,
            showBase64Images: false,
            base64ImageObjects: [],
            discount: JSON.parse(this.appointment.data)?.discount || 0,
            savedImages: [],
            medicationObject: {
                name: "unknown",
                quantity: 0,
                costPerItem: 0,
                totalCost: 0,
                data: {},
            },
            medicationRows: [],
            value: [],
            medications: [],
            form: new window.Form(this.appointment.getAttributes(), async (form) => {
                form.medications = this.medicationRows;
                form.data = {totalCost: this.totalCost, discount: this.discount};
                form.images = this.savedImages;

                this.appointment.update(form);
                await this.router.push({
                    name: "PetSummary",
                    params: {id: this.pet.id},
                });
            }),
        };
    },
    methods: {
        async deleteImage() {
            const i = this.selectedItem;

            this.savedImages = this.savedImages.filter(function (obj) {
                return obj.uuid !== i.uuid;
            });
            await Appointment.removeImage(this.selectedItem.id);

            this.closeConfirmDeleteModal();
        },
        showConfirmDeleteModal() {
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.isConfirmDeleteModalVisible = false;
        },
        async removeImage(item) {
            this.selectedItem = item;
            this.showConfirmDeleteModal();
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            await this.createImage(files[0]);
        },
        async createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;

                var base64imageObject = {
                    base64code: e.target.result,
                    filename: file.name,
                    id: file.name,
                };

                vm.base64ImageObjects.push(base64imageObject);
                vm.uploadImage(base64imageObject);
            };
            reader.readAsDataURL(file);
        },
        async uploadImage(base64imageObject) {
            const uploadedImage = await Appointment.uploadBase64Image(
                base64imageObject
            );

            this.savedImages.push(uploadedImage);

            this.savedImages = this.fixImagesForGallery(this.savedImages);
        },
        async getMedications() {
            const result = await Medication.getAvailableWithPrice();
            this.medications = result.data.filter((m) => m.batches.length > 0);
        },
        addMedicationComponent() {
            const med = {
                id: this.medicationRows.length + 1,
                quantity: 1,
                costPerItem: "0.00",
                totalCost: "0.00",
            };
            this.medicationRows.push(med);
        },
        getTotalCost() {
            this.medicationRows = this.medicationRows.map(function (m) {
                return {
                    ...m,
                    totalCost: parseFloat(m.quantity) * parseFloat(m.costPerItem),
                };
            });
        },
        getTotalAppointmentCost() {
            let totalCost = 0;
            this.medicationRows.map(function (m) {
                totalCost += parseFloat(m.totalCost);
            });

            return totalCost;
        },
        medicationIds() {
            if (!this.value) return [];

            const parsedMedicationIds = this.value.map((med) => med.value);

            return parsedMedicationIds;
        },
        getSelectedMedications() {
            if (!this.appointment.batches) return [];

            this.value = this.appointment.batches.map((batch) => ({
                value: batch.medication.id,
                name: batch.medication.name,
            }));
        },
        removeItem(index) {
            var element = document.getElementById(index);
            element.remove(element);

            this.medicationRows.splice(index - 1, 1);
        },
        selectMedicationOption(med) {
            const medFromList = this.medications.filter(
                (m) => m.id === med.name.value
            )[0];

            const medFromRows = this.medicationRows.filter((m) => m.id === med.id)[0];
            const medFromRowsIndex = this.medicationRows.findIndex(
                (m) => m.id === med.id
            );

            const newMed = {
                id: med.id,
                name: {
                    value: medFromList.id,
                    name: medFromList.name,
                },
                quantity: 1,
                costPerItem:
                medFromList.currentRetailPrice[
                medFromList.currentRetailPrice.length - 1
                    ].value,
                totalCost:
                medFromList.currentRetailPrice[
                medFromList.currentRetailPrice.length - 1
                    ].value,
            };

            this.medicationRows[medFromRowsIndex] = newMed;
        },
        setMedications() {
            const parsedMedicationData = JSON.parse(this.appointment.medication_data);
            this.medicationRows = parsedMedicationData ? parsedMedicationData : [];
        },
        fixImagesForGallery(images) {
            return images.map(function (i) {
                return {
                    id: i.id,
                    uuid: i.uuid,
                    largeURL: i.url ?? i.largeURL,
                    thumbnailURL: i.url ?? i.thumbnailURL,
                    height: 400,
                    width: 400,
                };
            });
        },
    },
    computed: {
        medicationOptions() {
            if (!this.medications) return [];

            const parsedMedications = this.medications.map((med) => ({
                value: med.id,
                name: med.name,
            }));

            return parsedMedications;
        },
        totalCost() {
            let consultPrice = 0;

            if (this.form.service === "follow_up_consult") {
                consultPrice = 15;
            } else if (this.form.service === "consult") {
                consultPrice = 30;
            }

            return parseFloat(
                consultPrice + this.getTotalAppointmentCost() - this.discount
            ).toFixed(2);
        },
    },
    mounted() {
        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#' + 'appointment-update-gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
            });
            this.lightbox.init();
        }
    },
    unmounted() {
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    beforeMount() {
        this.getMedications();
        this.getSelectedMedications();
        this.addMedicationComponent();
        this.setMedications();

        this.savedImages = this.fixImagesForGallery(
            this.appointment.animal_monitoring_images
        );
    },
};
</script>

<style scoped>
</style>
