import Model from './Model'

export default class User extends Model {

    static async updatePassword (id, form) {
        const data = await window.Http.put('users/' + id, form);
        return this.make(data)
    }

    static async updatePasswordViaToken (form) {
        const data = await window.Http.post('password/reset', form);
        return this.make(data)
    }

    static async sendPasswordResetLink (form) {
        const data = await window.Http.post('password/email', form);
        return this.make(data)
    }
}
