<template>
  <div>
    <select
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="h-12 bg-gray-100 border w-full rounded py-2 px-4 text-gray-700 focus:border-purple-500 border-gray-200"
    >
      <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          v-text="option.name"
          :selected="option.value === value"
      ></option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: null,
    label: String,
    options: Array
  },
  emits: ['update:modelValue'],
  computed: {
    inputError() {
      return this.error ? 'border-red-500' : 'border-gray-200'
    }
  },
}
</script>
