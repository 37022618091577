import Model from "./Model";

export default class Microchip extends Model {
    static async search(query) {
        let url = "/microchip/search";

        if (query) {
            url += "?query=" + query;
        }

        const response = await window.Http.post(url);
        return this.make(response);
    }

    static async searchBatches(query) {
        let url = "/microchip-boxes/listBatches";

        if (query) {
            url += "?query=" + query;
        }

        const response = await window.Http.get(url);
        return this.make(response.data);
    }

    static async authorisedSearch(microchip) {
        let url = "/microchips/authorisedSearch";

        if (microchip) {
            url += "?microchip=" + microchip;
        }

        const response = await window.Http.get(url);
        return this.make(response);
    }

    static async getBoxesByBatch(batch) {
        let url =
            "/microchip-boxes/listBoxes?batch=" + batch + "&include=microchips";

        const response = await window.Http.get(url);
        return this.make(response.data);
    }

    static async transferBoxesToTeam(batch, boxes) {
        let url = "/microchip-boxes/transferBoxes";

        const response = await window.Http.post(url, {batch, boxes});
        return this.make(response.data);
    }

    static async sendEmailForLostChip(value) {
        const response = await window.Http.post("microchip/report-lost", {
            microchip: value,
        });
        return this.make(response);
    }

    static async sendEmailForLostBatch(value) {
        const response = await window.Http.post("microchip-boxes/report-lost", {
            message: value,
        });
        return this.make(response);
    }

    static async register(form) {
        const response = await window.Http.post("/microchip/register", form);
        return this.make(response);
    }

    static async completeBatchRegistration(form) {
        const response = await window.Http.post("/microchip/complete-batch-registration", form);
        return this.make(response);
    }

    static async listRegistrationRequests(search, page, perPage) {
        let url = "/list-microchip-registrations?include=latest_email&";

        if (search) {
            url += "search=" + search;
        }

        const response = await window.Http.get(
            url + "&page=" + page + "&perPage=" + perPage
        );
        return this.make(response);
    }

    static async resendRegistrationRequestEmail(requestId, email = null) {
        if (email) {
            const response = await window.Http.post(
                "/microchips/resend-registration-email",
                {request_id: requestId, email}
            );
        } else {
            const response = await window.Http.post(
                "/microchips/resend-registration-email",
                {request_id: requestId}
            );
        }
    }
}