<template>
  <div class="rounded-full border-8 border-t-8 border-gray-200 ease-linear loader" :class="[height]"></div>
</template>

<script>
export default {
  props: {
    size: Number
  },
  computed: {
    height() {
      return 'h-' + this.size + ' w-' + this.size;
    }
  },
}
</script>

<style scoped>
.loader {
  border-top-color: #0074b5;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>