import Http from '../services/Http';

export interface PhoneNumberValidatedEntity {
    is_valid: boolean,
    country_code: string,
    phone_type: string,
}

export default class PhoneNumberService {

    static async validatePhoneNumber(phoneNumber: string, phoneType: string) {
        const response = await Http.post("/validate-phone-number", {phone_number: phoneNumber, phone_type: phoneType});

        return response as PhoneNumberValidatedEntity;
    }
}
