<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="
      bg-white
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
        @click="previous"
      >
        Previous
      </a>
      <a
        href="#"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
        @click="next"
      >
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ showPageFrom }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ showPageTo }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ resultsSize }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            href="#"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-l-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
            @click="previous"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <a
            href="#"
            aria-current="page"
            @click="
              switchPage(
                pageNumber > 2
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 5
                    : pageNumber - 2
                  : 1
              )
            "
            :class="
              getPaginationClass(
                pageNumber > 2
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 5
                    : pageNumber - 2
                  : 1
              )
            "
          >
            {{
              pageNumber > 2
                ? pageNumber >= maxPage - 2
                  ? maxPage - 5
                  : pageNumber - 2
                : 1
            }}
          </a>
          <a
            v-if="2 < maxPage"
            @click="
              switchPage(
                pageNumber > 3
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 4
                    : pageNumber - 1
                  : 2
              )
            "
            href="#"
            :class="
              getPaginationClass(
                pageNumber > 3
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 4
                    : pageNumber - 1
                  : 2
              )
            "
          >
            {{
              pageNumber > 3
                ? pageNumber >= maxPage - 2
                  ? maxPage - 4
                  : pageNumber - 1
                : 2
            }}
          </a>
          <a
            v-if="3 < maxPage"
            @click="
              switchPage(
                pageNumber > 3
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 3
                    : pageNumber
                  : 3
              )
            "
            href="#"
            :class="
              getPaginationClass(
                pageNumber > 3
                  ? pageNumber >= maxPage - 2
                    ? maxPage - 3
                    : pageNumber
                  : 3
              )
            "
          >
            {{
              pageNumber > 3
                ? pageNumber >= maxPage - 2
                  ? maxPage - 3
                  : pageNumber
                : 3
            }}
          </a>
          <span
            class="
              relative
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-700
            "
          >
            ...
          </span>
          <a
            v-if="maxPage > 5"
            href="#"
            @click="switchPage(maxPage - 2)"
            :class="getPaginationClass(maxPage - 2)"
          >
            {{ maxPage - 2 }}
          </a>
          <a
            v-if="maxPage > 4"
            href="#"
            @click="switchPage(maxPage - 1)"
            :class="getPaginationClass(maxPage - 1)"
          >
            {{ maxPage - 1 }}
          </a>
          <a
            :v-if="maxPage > pageNumber"
            href="#"
            @click="switchPage(maxPage)"
            :class="getPaginationClass(maxPage)"
          >
            {{ maxPage }}
          </a>
          <a
            href="#"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-r-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
            @click="next"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    pageSize: 0,
    pageNumber: 0,
    resultsSize: 0,
  },
  data() {
    return {};
  },
  beforeMount() {},
  methods: {
    async previous() {
      if (this.pageNumber == 1) return;
      await this.$emit("switchPage", { page: this.pageNumber - 1 });
    },
    async next() {
      if (this.pageNumber == this.maxPage) return;
      await this.$emit("switchPage", { page: this.pageNumber + 1 });
    },
    async switchPage(page) {
      await this.$emit("switchPage", { page });
      this.pageNumber = page;
    },
    getPaginationClass(number) {
      if (number == this.pageNumber) {
        return "z-10 bg-indigo-50 border-mp-blue text-mp-hover-blue relative inline-flex items-center px-4 py-2 border text-sm font-medium";
      }

      return "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium";
    },
  },
  computed: {
    showPageFrom() {
      if (this.pageSize == 0) return 0;
      if (this.pageNumber == 0) return 0;

      return (this.pageNumber - 1) * this.pageSize + 1;
    },
    showPageTo() {
      if (this.pageSize == 0) return 0;
      if (this.pageNumber == 0) return 0;

      if (this.pageNumber * this.pageSize > this.resultsSize) {
        return this.resultsSize;
      }

      return this.pageNumber * this.pageSize;
    },
    maxPage() {
      return Math.ceil(this.resultsSize / this.pageSize);
    },
  },
};
</script>
